import React from 'react'

const ArrowLinkButton: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      width="49"
      height="49"
      viewBox="0 0 49 49"
      fill="none"
      color="#0695D7"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.5 25.4683L35.5 25.4683M35.5 25.4683L25.5 35.4683M35.5 25.4683L25.5 15.4683"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ArrowLinkButton
