import './static/fonts/fonts.css'
import smoothscroll from 'smoothscroll-polyfill'

export const onServiceWorkerUpdateReady = () => {
  window.location.reload()
}

export const onClientEntry = () => {
  smoothscroll.polyfill()
}
