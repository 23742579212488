import React from 'react'

const AutodeskLogo: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="51"
      height="44"
      viewBox="0 0 51 44"
      fill="none"
      {...props}
    >
      <path
        d="M4.5518 24.1726L5.32532 22.0075L6.08607 24.1726H4.5518ZM4.4254 20.859L2.30603 26.3817H3.76267L4.1326 25.3462H6.49851L6.86228 26.3817H8.36734L6.22034 20.859H4.4254Z"
        fill="white"
      />
      <path
        d="M14.4016 15.9447L28.2807 7.3119H35.48C35.7123 7.3119 35.9006 7.49989 35.9008 7.73223C35.9008 7.73239 35.9008 7.73271 35.9008 7.73286C35.9116 7.88042 35.8331 8.02011 35.7016 8.08728L28.8848 12.1732C28.4943 12.4438 28.2689 12.8948 28.2867 13.3694L28.2797 15.9449H36.9418V0.976571C36.9429 0.683661 36.7064 0.445339 36.4136 0.444396C36.4051 0.444396 36.3966 0.44455 36.3883 0.444864H28.1254L14.2808 9.03979V15.9447H14.4016Z"
        fill="white"
      />
      <path
        d="M12.3561 24.2687C12.3561 24.9865 11.9144 25.2764 11.1618 25.2764C10.43 25.2764 9.95365 24.9726 9.95365 24.2687V20.859H8.54541V24.4272C8.54541 26.022 9.96749 26.519 11.1618 26.519C12.3492 26.519 13.7643 26.022 13.7643 24.4272V20.859H12.3561V24.2687Z"
        fill="white"
      />
      <path
        d="M14.4091 22.0531H16.1903V26.3817H17.5985V22.0531H19.3727V20.8588H14.4091V22.0531Z"
        fill="white"
      />
      <path
        d="M29.0017 25.1874H27.8832V22.0531H29.0017C29.8853 22.0531 30.1477 22.2949 30.1477 23.5789C30.1477 24.7526 29.8508 25.1874 29.0017 25.1874ZM29.0569 20.859H26.475V26.3817H29.0569C31.0382 26.3817 31.5836 25.6431 31.5836 23.5789C31.5836 21.6389 31.059 20.859 29.0569 20.859Z"
        fill="white"
      />
      <path
        d="M48.4431 20.8588H46.7655L44.7565 23.1025V20.8588H43.3483L43.355 26.3817H44.7565V24.283L46.662 26.3817H48.4431L45.8751 23.6547L48.4431 20.8588Z"
        fill="white"
      />
      <path
        d="M32.3702 26.3817H36.8161V25.1872H33.7785V24.1866H36.2223V22.9852H33.7785V22.0531H36.8161V20.8588H32.3702V26.3817Z"
        fill="white"
      />
      <path
        d="M22.6511 25.2772C21.6086 25.2772 21.0355 24.6421 21.0355 23.6065C21.0355 22.5916 21.6086 21.9496 22.6511 21.9496C23.7005 21.9496 24.2665 22.5916 24.2665 23.6065C24.2665 24.6421 23.7005 25.2772 22.6511 25.2772ZM22.6511 20.707C20.6421 20.707 19.5996 21.9427 19.5996 23.6065C19.5996 25.2772 20.6421 26.5197 22.6511 26.5197C24.6669 26.5197 25.7094 25.2772 25.7094 23.6065C25.7094 21.9427 24.6669 20.707 22.6511 20.707Z"
        fill="white"
      />
      <path
        d="M41.0761 23.13L39.792 22.9852C39.0394 22.9023 38.8531 22.7158 38.8531 22.4604C38.8531 22.1775 39.2398 21.9221 39.9922 21.9221C40.7169 21.9221 41.0969 22.1912 41.1658 22.5985H42.5465C42.4776 21.4319 41.497 20.707 40.068 20.707C38.3699 20.707 37.5138 21.3834 37.5138 22.5777C37.5138 23.5513 38.08 24.0067 39.2535 24.1312L40.5584 24.2691C41.0553 24.3245 41.2831 24.5178 41.2831 24.7664C41.2831 25.0492 40.9518 25.3047 40.1509 25.3047C39.226 25.3047 38.8531 25.07 38.7909 24.6006H37.438C37.5 25.8707 38.3285 26.5197 40.1027 26.5197C41.725 26.5197 42.6294 25.8087 42.6294 24.7387C42.6294 23.8066 42.0909 23.2474 41.0761 23.13Z"
        fill="white"
      />
      <path
        d="M0 33.7127L0.388476 33.2595C0.79852 33.5329 1.08622 33.7415 1.80568 33.7415C2.61847 33.7415 2.95662 33.3243 2.95662 32.9573V32.8639C2.95662 32.4466 2.76244 32.2595 2.12933 32.0509L1.37399 31.8065C0.525068 31.5329 0.201356 31.1014 0.201356 30.5548V30.3963C0.201356 29.7633 0.805708 29.1446 1.94946 29.1446C2.68329 29.1446 3.23007 29.3749 3.676 29.7994L3.28752 30.2165C2.84152 29.8568 2.50343 29.7273 1.95665 29.7273C1.22296 29.7273 0.935253 30.0943 0.935253 30.4036V30.5403C0.935253 30.8208 1.18701 31.0511 1.79124 31.238L2.46023 31.4465C3.15091 31.6696 3.69044 32.0078 3.69044 32.7846V32.9646C3.69044 33.7703 2.95662 34.353 1.81287 34.353C0.935253 34.353 0.388476 34.0725 0 33.7127Z"
        fill="white"
      />
      <path
        d="M6.85472 32.0509C6.85472 31.3533 6.50951 30.9359 5.89796 30.9359C5.27928 30.9359 4.95563 31.3533 4.93394 32.0942H6.85472V32.0509ZM7.48055 32.605H4.93394C4.94838 33.4106 5.344 33.7848 6.04912 33.7848C6.48788 33.7848 6.77558 33.6264 7.06335 33.4609L7.33677 33.907C7.03457 34.1444 6.5527 34.353 6.00593 34.353C4.88355 34.353 4.229 33.5904 4.229 32.5545V32.1661C4.229 31.1588 4.85486 30.3748 5.89796 30.3748C6.89067 30.3748 7.50226 31.0294 7.50226 32.1947C7.50226 32.3386 7.49502 32.4969 7.48055 32.605Z"
        fill="white"
      />
      <path
        d="M10.3939 30.4612L10.2643 31.1374C10.1924 31.123 10.1061 31.1157 10.034 31.1157C9.56665 31.1157 9.19257 31.3171 8.97674 31.9287V34.2666H8.2572V30.4612H8.80401L8.91916 31.0797C9.13499 30.5689 9.55218 30.3893 9.94073 30.3893C10.1493 30.3893 10.2931 30.4251 10.3939 30.4612Z"
        fill="white"
      />
      <path
        d="M12.6245 34.2666H11.7468L10.4591 30.4612H11.2143L12.2001 33.6767L13.1856 30.4612H13.9122L12.6245 34.2666Z"
        fill="white"
      />
      <path
        d="M14.5603 29.3605C14.5603 29.1086 14.7546 28.9072 15.0065 28.9072C15.2583 28.9072 15.4597 29.1086 15.4597 29.3605C15.4597 29.6049 15.2583 29.8066 15.0065 29.8066C14.7546 29.8066 14.5603 29.6049 14.5603 29.3605ZM15.4021 30.4612V34.2666H14.6826V31.0078H14.0856V30.4612H15.4021Z"
        fill="white"
      />
      <path
        d="M16.1647 32.5186V32.2019C16.1647 31.1157 16.7618 30.3748 17.8553 30.3748C18.3518 30.3748 18.7401 30.533 19.0854 30.8639L18.7258 31.2597C18.4812 31.0438 18.2294 30.9359 17.8768 30.9359C17.2581 30.9359 16.8841 31.3674 16.8841 32.1876V32.5329C16.8841 33.3459 17.2366 33.7848 17.8625 33.7848C18.2294 33.7848 18.4955 33.6336 18.7401 33.4681L19.0494 33.9141C18.7472 34.173 18.294 34.353 17.848 34.353C16.7834 34.353 16.1647 33.6048 16.1647 32.5186Z"
        fill="white"
      />
      <path
        d="M21.9854 32.0509C21.9854 31.3533 21.6403 30.9359 21.0286 30.9359C20.4101 30.9359 20.0864 31.3533 20.0646 32.0942H21.9854V32.0509ZM22.6113 32.605H20.0646C20.0791 33.4106 20.4748 33.7848 21.1798 33.7848C21.6185 33.7848 21.9063 33.6264 22.194 33.4609L22.4675 33.907C22.1654 34.1444 21.6834 34.353 21.1367 34.353C20.0143 34.353 19.3597 33.5904 19.3597 32.5545V32.1661C19.3597 31.1588 19.9855 30.3748 21.0286 30.3748C22.0214 30.3748 22.633 31.0294 22.633 32.1947C22.633 32.3386 22.6258 32.4969 22.6113 32.605Z"
        fill="white"
      />
      <path
        d="M27.7336 30.6913C27.7336 30.0871 27.3307 29.8137 26.6905 29.8137H25.8847V31.8999H26.5753C27.3092 31.8999 27.7336 31.6337 27.7336 31.015V30.6913ZM28.4673 30.6553V31.0438C28.4673 31.9646 27.7264 32.4897 26.5898 32.4897H25.8847V34.2666H25.1509V29.2308H25.8847H26.712C27.7768 29.2308 28.4673 29.7345 28.4673 30.6553Z"
        fill="white"
      />
      <path
        d="M31.2077 30.4612L31.0783 31.1374C31.0064 31.123 30.92 31.1157 30.848 31.1157C30.3805 31.1157 30.0062 31.3171 29.7905 31.9287V34.2666H29.0712V30.4612H29.618L29.733 31.0797C29.9488 30.5689 30.3661 30.3893 30.7545 30.3893C30.9631 30.3893 31.1071 30.4251 31.2077 30.4612Z"
        fill="white"
      />
      <path
        d="M32.0855 32.1445V32.5472C32.0855 33.3387 32.4378 33.7848 33.1069 33.7848C33.7759 33.7848 34.1429 33.3387 34.1429 32.5472V32.1445C34.1429 31.3819 33.7759 30.9359 33.1069 30.9359C32.4378 30.9359 32.0855 31.3819 32.0855 32.1445ZM34.8625 32.1588V32.5545C34.8625 33.5976 34.2508 34.353 33.1069 34.353C31.9632 34.353 31.3661 33.5976 31.3661 32.5545V32.1588C31.3661 31.1374 31.9632 30.3748 33.1069 30.3748C34.2508 30.3748 34.8625 31.1374 34.8625 32.1588Z"
        fill="white"
      />
      <path
        d="M37.2808 34.2666H36.4031L35.1152 30.4612H35.8706L36.8563 33.6767L37.8417 30.4612H38.5683L37.2808 34.2666Z"
        fill="white"
      />
      <path
        d="M39.2165 29.3605C39.2165 29.1086 39.4107 28.9072 39.6626 28.9072C39.9143 28.9072 40.1156 29.1086 40.1156 29.3605C40.1156 29.6049 39.9143 29.8066 39.6626 29.8066C39.4107 29.8066 39.2165 29.6049 39.2165 29.3605ZM40.0581 30.4612V34.2666H39.3387V31.0078H38.7417V30.4612H40.0581Z"
        fill="white"
      />
      <path
        d="M43.3526 33.2882V31.31C43.1727 31.1014 42.885 30.9359 42.5037 30.9359C41.921 30.9359 41.5757 31.3964 41.5757 32.1588V32.5689C41.5757 33.3818 41.9138 33.7848 42.4606 33.7848C42.8633 33.7848 43.1295 33.5976 43.3526 33.2882ZM44.0719 28.9431V34.2666H43.4893L43.4174 33.835C43.1655 34.1516 42.8274 34.353 42.3598 34.353C41.4678 34.353 40.8562 33.6264 40.8562 32.5545V32.1733C40.8562 31.1447 41.4678 30.3748 42.4101 30.3748C42.7987 30.3748 43.0936 30.4826 43.3526 30.7273V28.9431H44.0719Z"
        fill="white"
      />
      <path
        d="M47.4599 32.0509C47.4599 31.3533 47.1146 30.9359 46.5032 30.9359C45.8845 30.9359 45.5607 31.3533 45.5392 32.0942H47.4599V32.0509ZM48.0857 32.605H45.5392C45.5536 33.4106 45.9491 33.7848 46.6543 33.7848C47.0931 33.7848 47.3808 33.6264 47.6685 33.4609L47.9419 33.907C47.6397 34.1444 47.1579 34.353 46.6111 34.353C45.4887 34.353 44.8341 33.5904 44.8341 32.5545V32.1661C44.8341 31.1588 45.46 30.3748 46.5032 30.3748C47.4958 30.3748 48.1074 31.0294 48.1074 32.1947C48.1074 32.3386 48.1002 32.4969 48.0857 32.605Z"
        fill="white"
      />
      <path
        d="M50.9989 30.4612L50.8693 31.1374C50.7974 31.123 50.7112 31.1157 50.6391 31.1157C50.1716 31.1157 49.7974 31.3171 49.5817 31.9287V34.2666H48.8623V30.4612H49.409L49.5241 31.0797C49.7399 30.5689 50.1571 30.3893 50.5457 30.3893C50.7543 30.3893 50.898 30.4251 50.9989 30.4612Z"
        fill="white"
      />
      <path
        d="M15.3404 38.455C15.3404 37.8508 14.9374 37.5774 14.2973 37.5774H13.4915V39.6638H14.1821C14.916 39.6638 15.3404 39.3975 15.3404 38.7789V38.455ZM16.0741 38.4191V38.8076C16.0741 39.7284 15.3332 40.2535 14.1964 40.2535H13.4915V42.0305H12.7577V36.9947H13.4915H14.3188C15.3835 36.9947 16.0741 37.4983 16.0741 38.4191Z"
        fill="white"
      />
      <path
        d="M18.8164 38.2249L18.6869 38.9011C18.615 38.8867 18.5287 38.8794 18.4566 38.8794C17.9891 38.8794 17.615 39.0809 17.3992 39.6924V42.0305H16.6798V38.2249H17.2265L17.3416 38.8436C17.5574 38.3328 17.9746 38.153 18.3632 38.153C18.5718 38.153 18.7156 38.189 18.8164 38.2249Z"
        fill="white"
      />
      <path
        d="M19.6941 39.9082V40.3111C19.6941 41.1024 20.0465 41.5485 20.7155 41.5485C21.3846 41.5485 21.7516 41.1024 21.7516 40.3111V39.9082C21.7516 39.1458 21.3846 38.6997 20.7155 38.6997C20.0465 38.6997 19.6941 39.1458 19.6941 39.9082ZM22.4709 39.9225V40.3183C22.4709 41.3614 21.8595 42.1167 20.7155 42.1167C19.5719 42.1167 18.9747 41.3614 18.9747 40.3183V39.9225C18.9747 38.9011 19.5719 38.1385 20.7155 38.1385C21.8595 38.1385 22.4709 38.9011 22.4709 39.9225Z"
        fill="white"
      />
      <path
        d="M25.4568 40.9514V39.1458C25.2337 38.8581 24.9603 38.6998 24.5792 38.6998C24.0827 38.6998 23.6871 38.9803 23.6871 39.9155V40.2608C23.6871 41.1887 24.0324 41.4621 24.5071 41.4621C24.8883 41.4621 25.2193 41.2751 25.4568 40.9514ZM26.1762 38.2176V42.1096C26.1762 43.0303 25.4927 43.5556 24.5792 43.5556C24.0251 43.5556 23.5719 43.4261 23.2121 43.2103L23.4424 42.7571C23.7662 42.9154 24.0827 42.9872 24.5287 42.9872C25.1329 42.9872 25.4782 42.7066 25.4782 42.0808V41.534C25.2193 41.829 24.8669 42.0305 24.4137 42.0305C23.6366 42.0305 22.9675 41.5268 22.9675 40.268V39.9298C22.9675 38.7068 23.6943 38.1385 24.4639 38.1385C24.9172 38.1385 25.241 38.2826 25.5142 38.5703L25.5646 38.2176H26.1762Z"
        fill="white"
      />
      <path
        d="M29.3778 38.2249L29.2482 38.9011C29.1763 38.8867 29.0899 38.8794 29.0179 38.8794C28.5505 38.8794 28.1763 39.0809 27.9606 39.6924V42.0305H27.2412V38.2249H27.7879L27.903 38.8436C28.1188 38.3328 28.536 38.153 28.9246 38.153C29.1332 38.153 29.277 38.189 29.3778 38.2249Z"
        fill="white"
      />
      <path
        d="M31.7444 41.124V40.2032H31.0393C30.4496 40.2032 30.1402 40.4192 30.1402 40.8434V41.0018C30.1402 41.3254 30.3632 41.5555 30.7804 41.5555C31.1545 41.5555 31.4998 41.419 31.7444 41.124ZM32.4638 39.4335V42.0305H31.8811L31.8308 41.6419C31.4998 41.9658 31.1114 42.1167 30.6653 42.1167C30.0179 42.1167 29.4208 41.7211 29.4208 41.052V40.8001C29.4208 40.1456 30.0107 39.7068 30.9892 39.7068H31.7444V39.419C31.7444 38.9299 31.507 38.6997 31.0105 38.6997C30.5789 38.6997 30.2553 38.8075 29.8812 38.9947L29.6511 38.5198C30.0252 38.304 30.4998 38.1385 31.061 38.1385C32.0106 38.1385 32.4638 38.6422 32.4638 39.4335Z"
        fill="white"
      />
      <path
        d="M38.4924 39.3902V42.0305H37.773V39.4552C37.773 38.9155 37.5067 38.6998 37.1687 38.6998C36.8377 38.6998 36.5717 38.9084 36.3341 39.2607V42.0305H35.6148V39.448C35.6148 38.9227 35.3558 38.6998 35.0178 38.6998C34.687 38.6998 34.4134 38.9084 34.1832 39.2607V42.0305H33.4639V38.2249H34.0753L34.1257 38.6494C34.3848 38.3257 34.7301 38.1385 35.1688 38.1385C35.5932 38.1385 35.9818 38.34 36.1831 38.7284C36.4781 38.3545 36.8449 38.1385 37.3198 38.1385C37.9384 38.1385 38.4924 38.5776 38.4924 39.3902Z"
        fill="white"
      />
    </svg>
  )
}

export default AutodeskLogo
