export enum FormState {
  Ready = 'ready',
  Processing = 'processing',
  Done = 'done',
  Error = 'error',
}

export const nameSurnameRegex = /^[a-zA-Zа-яёА-ЯЁ\s\-]+$/u
export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/
export const phoneRegex = /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){11,14}(\s*)?$/

export const SIGNAL_GOOGLE_SHEET_URL =
  'https://script.google.com/macros/s/AKfycbwQydS-ZTOLGbEPNErPn2M9Rj_Z4ZKR01qK7mPhcPCT7FUATpXCbiCC2lfZk3FPCQee/exec'
export const SIGNAX_GOOGLE_SHEET_URL =
  'https://script.google.com/macros/s/AKfycbzUauh9vFYduwoVTcRF-fBvryRFETSp1wyzyXCyU4xzlZnn8tfz0W50_8PduBJdfPaFoA/exec'
