import React from 'react'

interface IProps extends React.SVGProps<SVGSVGElement> {
  logoRef?: React.RefObject<SVGSVGElement>
}

const LogoEn: React.FC<IProps> = ({ className, logoRef }) => (
  <svg
    width="280"
    height="331"
    viewBox="0 0 280 331"
    fill="none"
    color="white"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    ref={logoRef}
  >
    <path
      d="M70.7849 9.86865C70.324 13.3125 70.1265 16.8889 70.1265 20.5315C70.1265 58.4142 95.8066 84.707 146.838 99.4097L161.126 103.251C167.382 104.973 171.793 106.033 174.361 107.092C176.929 107.821 180.222 108.814 183.514 110.271C190.494 113.053 192.337 116.96 192.337 121.464C192.337 129.875 183.909 134.114 167.382 134.114C144.994 134.114 129.586 123.583 121.157 102.92L58.4058 137.624C71.2458 175.506 109.766 199.349 165.538 199.349C174.954 199.349 183.777 198.686 192.074 197.362L0 255.312L70.7849 9.86865Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M198.196 33.8428C191.217 16.6896 179.496 7.8812 162.969 7.8812C150.129 7.8812 143.544 13.5106 143.544 20.862C143.544 26.0941 146.507 30.0016 151.972 32.7832C157.833 35.5648 168.829 39.0749 185.356 43.6447C195.563 46.5587 200.172 47.7508 207.547 50.5987L259.236 0H258.841L198.196 33.8428Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.9265 278.838C25.8114 278.037 29.0379 279.208 31.8693 280.81V268.607C27.7868 267.559 24.3628 267.498 19.6219 268.669C7.7695 271.688 1.44824 280.933 1.44824 289.685C1.44824 297.759 6.45256 301.149 14.2883 304.723C16.5929 305.833 20.4779 307.373 21.729 309.53C22.2557 310.517 22.4533 311.441 22.4533 312.55C22.4533 316.741 19.0292 319.453 14.2883 319.638C10.4033 319.823 7.17689 318.59 4.01626 317.05V327.65C7.57197 329.561 11.7861 330.547 16.0662 330.547C28.7087 330.547 35.9518 321.795 35.9518 310.763C35.9518 292.89 15.0126 296.095 15.0126 285.925C15.0126 282.597 17.844 279.701 21.9265 278.838Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M45.0381 330.54H58.8657V256.827L45.0381 260.993V330.54Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M116.284 280.65C116.416 282.836 116.416 285.087 116.416 287.338C116.416 293.511 116.218 299.814 115.165 305.923C112.86 319.427 105.881 330.745 94.0282 330.745C75.1961 330.745 69.2041 308.817 69.2041 292.097C69.2041 274.798 74.406 251.647 94.0282 246.438C104.695 243.609 111.609 250.04 114.506 261.615L101.14 270.618C100.481 265.859 99.3617 259.493 94.1599 260.65C84.7438 262.708 84.0195 282.45 84.0195 289.91C84.0195 297.756 84.3488 316.469 94.2916 316.019C101.798 315.697 102.654 304.315 102.786 297.498L94.0282 298.399V283.93L116.284 280.65Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M156.913 330.738L139.727 272.551C140.253 276.072 140.451 279.792 140.451 283.446V330.746H126.623V236.608L139.557 232.713L156.912 290.621C156.254 286.17 155.859 281.652 155.859 277.135V227.798L169.884 223.574V330.738H156.913Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M209.516 330.73L206.891 311.471L192.997 312.133L190.49 330.743H176.667L193.436 216.428L206.266 212.611L224.144 330.73H209.516ZM204.783 294.119C203.335 283.39 201.886 272.926 200.833 262.13C200.438 257.296 199.977 252.329 199.713 247.56C199.055 263.455 197.079 279.284 195.104 294.98L204.783 294.119Z"
      fill="currentColor"
    />
    <path
      d="M280.001 330.747L236.917 203.385L223.687 207.361L266.627 330.747"
      fill="currentColor"
    />
    <path
      d="M243.897 330.747L277.805 191.069L261.552 195.955L230.675 330.747"
      fill="currentColor"
    />
  </svg>
)
export default LogoEn
