import React from 'react'
import { useTranslations } from '../../hooks/use-translations'
import Modal from '../modal'
import Button from '../button/button'

interface FormErrorProps {
  onCLose: () => void
}

const FormError: React.FC<FormErrorProps> = ({ onCLose }) => {
  const { t } = useTranslations()
  const email = t('support_email')

  return (
    <Modal onClose={onCLose}>
      <div className="content is-medium">
        <h6 className="title is-2 has-text-danger">{t('Sending error')}.</h6>
        <p>
          {t('Try again later or write to us')}: <br />
          <a href={`mailto:${email}`}>{email}</a>
        </p>
        <Button
          role="button"
          size="medium"
          color="dark-blue"
          className="py-3 px-6"
          onClick={onCLose}
        >
          {t('Close')}
        </Button>
      </div>
    </Modal>
  )
}

export default FormError
