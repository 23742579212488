import { createContext } from 'react'
import { DEFAULT_LOCALE } from '../data'
import { ILocalesMap } from '../i18n/types'

interface ILocaleContext {
  locale: string
  locales: ILocalesMap
  t: Record<string, string>
}

export const LocaleContext = createContext<ILocaleContext>({
  locale: DEFAULT_LOCALE,
  locales: {},
  t: {},
})
