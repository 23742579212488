import React from 'react'

const SignalLogoMobileBlueBack: React.FC<React.SVGProps<SVGSVGElement>> = ({
  style,
  ...props
}) => {
  return (
    <svg
      width="58"
      height="76"
      viewBox="0 0 58 76"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        fill: 'none',
        boxShadow:
          '4.048678398132324px 4.048678398132324px 4.048678398132324px 0px rgba(40, 187, 255, 1) inset, 1.012169599533081px 1.012169599533081px 20.243392944335938px 0px rgba(11, 152, 217, 0.3)',
        borderRadius: '0px 0px 8px 8px',
        ...style,
      }}
      {...props}
    >
      <g filter="url(#filter0_i_15273_48202)">
        <path
          d="M0 0H58V68C58 72.4183 54.4183 76 50 76H8C3.58172 76 0 72.4183 0 68V0Z"
          fill="url(#paint0_linear_15273_48202)"
        />
        <path
          d="M1 1H57V68C57 71.866 53.866 75 50 75H8C4.13401 75 1 71.866 1 68V1Z"
          stroke="url(#paint1_linear_15273_48202)"
          strokeWidth="2"
        />
        <path
          d="M21.2838 17.3126C21.2233 17.7708 21.1974 18.2466 21.1974 18.7311C21.1974 23.7708 24.5655 27.2686 31.2584 29.2245L33.1324 29.7355C33.9529 29.9646 34.5315 30.1055 34.8683 30.2465C35.2051 30.3434 35.6369 30.4756 36.0687 30.6694C36.9841 31.0395 37.2259 31.5593 37.2259 32.1584C37.2259 33.2773 36.1205 33.8412 33.9529 33.8412C31.0166 33.8412 28.9958 32.4403 27.8904 29.6915L19.6602 34.3082C21.3442 39.3478 26.3963 42.5196 33.7111 42.5196C34.946 42.5196 36.1032 42.4315 37.1914 42.2553L12 49.9645L21.2838 17.3126Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.9944 20.5022C37.079 18.2203 35.5417 17.0485 33.3741 17.0485C31.6901 17.0485 30.8265 17.7974 30.8265 18.7753C30.8265 19.4714 31.2151 19.9912 31.9319 20.3612C32.7005 20.7313 34.1427 21.1982 36.3104 21.8062C37.6489 22.1938 38.2535 22.3524 39.2207 22.7313L46 16H45.9482L37.9944 20.5022Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.8758 53.0946C15.3853 52.9881 15.8085 53.1438 16.1799 53.357V51.7336C15.6444 51.5942 15.1954 51.586 14.5736 51.7418C13.0191 52.1436 12.19 53.3734 12.19 54.5377C12.19 55.6117 12.8463 56.0627 13.874 56.5382C14.1763 56.6858 14.6858 56.8908 14.8499 57.1778C14.919 57.3089 14.9449 57.4319 14.9449 57.5795C14.9449 58.137 14.4958 58.4978 13.874 58.5224C13.3645 58.547 12.9413 58.383 12.5268 58.178V59.5883C12.9932 59.8424 13.5459 59.9736 14.1072 59.9736C15.7653 59.9736 16.7153 58.8094 16.7153 57.3417C16.7153 54.964 13.969 55.3904 13.969 54.0375C13.969 53.5948 14.3404 53.2094 14.8758 53.0946Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.9069 59.9724H19.7205V50.1661L17.9069 50.7203V59.9724Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.2512 53.3355C27.2685 53.6263 27.2685 53.9258 27.2685 54.2252C27.2685 55.0465 27.2426 55.8848 27.1044 56.6976C26.8022 58.4941 25.8867 59.9998 24.3322 59.9998C21.8623 59.9998 21.0765 57.0825 21.0765 54.8582C21.0765 52.557 21.7587 49.4772 24.3322 48.7842C25.7313 48.4078 26.6381 49.2633 27.0181 50.8032L25.2649 52.0009C25.1786 51.3678 25.0318 50.5209 24.3495 50.6749C23.1146 50.9486 23.0196 53.575 23.0196 54.5674C23.0196 55.6111 23.0627 58.1006 24.3668 58.0407C25.3513 57.9979 25.4636 56.4837 25.4808 55.5769L24.3322 55.6966V53.7718L27.2512 53.3355Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.5799 59.999L30.3257 52.2582C30.3948 52.7266 30.4207 53.2215 30.4207 53.7076V60H28.6072V47.4767L30.3035 46.9585L32.5798 54.662C32.4934 54.0699 32.4416 53.4689 32.4416 52.868V46.3046L34.2811 45.7427V59.999H32.5799Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.479 59.9977L39.1347 57.4356L37.3125 57.5237L36.9837 59.9995H35.1708L37.3701 44.7919L39.0528 44.2841L41.3977 59.9977H39.479ZM38.8584 55.1273C38.6684 53.7 38.4784 52.3079 38.3402 50.8718C38.2884 50.2286 38.2279 49.5678 38.1934 48.9335C38.107 51.048 37.848 53.1537 37.5889 55.2418L38.8584 55.1273Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M42.2871 43.294V59.9994H45.992V56.8012L44.092 56.9069V42.7453L42.2871 43.294Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_i_15273_48202"
          x="0"
          y="0"
          width="62.0487"
          height="80.0487"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="4.04868" dy="4.04868" />
          <feGaussianBlur stdDeviation="2.02434" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.158334 0 0 0 0 0.731643 0 0 0 0 1 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_15273_48202"
          />
        </filter>
        <linearGradient
          id="paint0_linear_15273_48202"
          x1="0"
          y1="0"
          x2="60.3879"
          y2="1.94725"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0695D7" />
          <stop offset="1" stopColor="#3AB7F0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_15273_48202"
          x1="0"
          y1="0"
          x2="60.3879"
          y2="1.94725"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#23A9E8" stopOpacity="0.99" />
          <stop offset="1" stopColor="#50B9EA" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default SignalLogoMobileBlueBack
