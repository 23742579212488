import React from 'react'
import ReactDOM from 'react-dom'
import cn from 'classnames'
import { isBrowser } from '../../utils'

interface IProps {
  spaceless?: boolean
  full?: boolean
  contentClassName?: string
  boxClassName?: string
  onClose: () => void
}

const Modal: React.FC<IProps> = ({
  spaceless,
  full,
  contentClassName,
  boxClassName,
  onClose,
  children,
}) => {
  React.useEffect(() => {
    document.getElementsByTagName('html')[0].style.overflowY = 'hidden'
    return () => {
      document.getElementsByTagName('html')[0].style.overflowY = 'scroll'
    }
  }, [])

  return isBrowser() && children
    ? ReactDOM.createPortal(
        <div
          className={cn('modal is-active', full && 'full')}
          style={{ zIndex: 10000 }}
        >
          <div className="modal-overlay" onClick={onClose}></div>
          <div className={cn('modal-content', contentClassName)}>
            <div
              className={cn(
                'modal-box is-block has-background-white',
                spaceless && 'px-0 py-0',
                boxClassName
              )}
            >
              {children}
            </div>
            <button
              className="modal-close is-pinned"
              aria-label="close"
              onClick={onClose}
            ></button>
          </div>
        </div>,
        document.body
      )
    : null
}

export default Modal
