import React from 'react'

const FlagEsIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12.7334" r="11" fill="white" />
      <g clipPath="url(#clip0_24433_1205)">
        <rect x="2" y="2.7334" width="20" height="20" fill="#FDD008" />
        <rect x="2" y="2.7334" width="24" height="3" fill="#EE1C24" />
        <rect x="2" y="19.7334" width="24" height="3" fill="#EE1C24" />
        <path
          d="M6 10.7334H9V13.7334C9 14.2857 8.55228 14.7334 8 14.7334H7C6.44772 14.7334 6 14.2857 6 13.7334V10.7334Z"
          fill="#FDD008"
          stroke="#EE1C24"
          strokeWidth="2"
        />
      </g>
      <defs>
        <clipPath id="clip0_24433_1205">
          <rect x="2" y="2.7334" width="20" height="20" rx="10" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default FlagEsIcon
