import React from 'react'
import { Link, navigate } from 'gatsby'
import cn from 'classnames'

import { Button } from '../Common/Button'
import Navigation from '../Navigation'
import ContactButton from '../Common/ContactButton'
import ContactForm from '../contact-form'

import SignaxLogoBlueBack from '../../svg/signax-logo-blue-back'
import SignalLogoBlueBack from '../../svg/signal-logo-blue-back'
import AutodeskLogo from '../../svg/autodesk-logo'

import { toLink } from '../../utils'
import { useTranslations } from '../../hooks/use-translations'
import { GlobalContext } from '../../context/GlobalContext'
import { AppRegionEnum } from '../../types/app-region.enum'
import {
  appSgnl,
  autodeskSignaxLink,
  telegramLink,
  vkLink,
  youtubeLink,
  whatsappLinkUs,
  linkedinLinkUs,
  youtubeLinkUs,
} from '../../data'
import { PathEnum } from '../../types/pages'

import TelegramBtnIcon from '../../svg/telegram-btn-icon'
import YoutubeBtnIcon from '../../svg/youtube-btn-icon'
import VkBtnIcon from '../../svg/vk-btn-icon'
import WhatsappBtnIcon from '../../svg/whatsapp-btn-icon'
import LinkedinBtnIcon from '../../svg/linkedin-btn-icon'

import * as s from './header.module.scss'
import { LanguageSwitch } from '../languageSwitch'

const MenuDesktop = () => {
  const { getPath, changeLang, t, locale } = useTranslations()
  const { region, siteTitle, location } = React.useContext(GlobalContext)
  const [isOpenModal, setIsOpenModal] = React.useState(false)
  const path = location?.pathname || '/'
  return (
    <>
      <div
        className={cn(
          'flex justify-between items-center w-100 h-100',
          s.is_hidden_mobile
        )}
      >
        <div className="flex items-center">
          <div
            className={cn(region === AppRegionEnum.Us && s.logo_wrapper)}
            style={{ marginRight: region === AppRegionEnum.Ru ? 80 : 109 }}
          >
            <Link to={getPath(PathEnum.Home)} title={siteTitle}>
              {renderLogo(region as AppRegionEnum)}
            </Link>
          </div>
          <Navigation />
        </div>

        {region === AppRegionEnum.Ru ? (
          <div className="flex">
            <div className="flex" style={{ gap: 6, marginRight: 32 }}>
              <ContactButton
                Icon={<TelegramBtnIcon />}
                color="transparent"
                href={telegramLink}
                className={s.btn_contact}
              />
              <ContactButton
                Icon={<YoutubeBtnIcon />}
                color="transparent"
                href={youtubeLink}
                className={s.btn_contact}
              />
              <ContactButton
                Icon={<VkBtnIcon />}
                color="transparent"
                href={vkLink}
                className={s.btn_contact}
              />
            </div>
            <Button
              size="small"
              className={s.btn_login}
              onClick={() => {
                toLink(appSgnl)
              }}
            >
              {t(`Login`)}
            </Button>
          </div>
        ) : (
          <div className="flex items-center">
            <div className="flex" style={{ gap: 6, marginRight: 32 }}>
              <ContactButton
                Icon={<WhatsappBtnIcon />}
                color="transparent"
                href={whatsappLinkUs}
                className={s.btn_contact}
              />
              <ContactButton
                Icon={<LinkedinBtnIcon />}
                color="transparent"
                href={linkedinLinkUs}
                className={s.btn_contact}
              />
              <ContactButton
                Icon={<YoutubeBtnIcon />}
                color="transparent"
                href={youtubeLinkUs}
                className={s.btn_contact}
              />
              {region === AppRegionEnum.Us && (
                <LanguageSwitch
                  type="desktop"
                  onLangClick={lang => navigate(changeLang(path, lang))}
                  activeLang={locale}
                />
              )}
            </div>

            <button className={s.btn_demo} onClick={() => setIsOpenModal(true)}>
              {t('Request Demo')}
            </button>
            <a href={autodeskSignaxLink} target={'_blank'} rel="noreferrer">
              <AutodeskLogo />
            </a>
          </div>
        )}
      </div>

      {isOpenModal && (
        <ContactForm
          onClose={() => setIsOpenModal(false)}
          title={t('Request a demo of Signal')}
          requestType="Demo"
        />
      )}
    </>
  )
}

export default MenuDesktop

const renderLogo = (region: AppRegionEnum) => {
  return (
    <>
      {region === AppRegionEnum.Ru ? (
        <SignalLogoBlueBack style={{ position: 'relative', top: 4 }} />
      ) : (
        <SignaxLogoBlueBack style={{ position: 'relative', top: 4 }} />
      )}
    </>
  )
}
