import { AppRegionEnum } from './types/app-region.enum'
import device from 'current-device'

export function getScrollTop(): number {
  const body = document?.body
  const docEl = document?.documentElement
  return (
    (window.pageYOffset || docEl?.scrollTop || body?.scrollTop || 0) -
    (docEl?.clientTop || 0)
  )
}

export const isBrowser = (): boolean => typeof window !== 'undefined'

export const toLink = (link: string) => {
  window.open(link)
}

export const getMailUrl = (hostName: string, region: AppRegionEnum) => {
  const mailUrlSgnl = `${
    process.env.NODE_ENV === 'production'
      ? `https://message.${hostName.slice(-8)}`
      : 'https://message.sgnl.dev'
  }/api/public/mail/sgnl`

  const mailUrlSgnx = `${
    process.env.NODE_ENV === 'production'
      ? `https://message.${hostName}`
      : 'https://message.signax.dev'
  }/api/public/mail/sgnl`

  return region === AppRegionEnum.Ru ? mailUrlSgnl : mailUrlSgnx
}

export const createFillArray = <T,>(
  length: number,
  fillCb: (i: number) => T
): T[] =>
  Array(length)
    .fill(0)
    .map((_, i) => fillCb(i))

export const getDeviceLink = ({
  iosLink,
  androidLink,
  desktopLink,
}: {
  iosLink: string
  androidLink: string
  desktopLink: string
}) => {
  if (typeof device.ios === 'function' && device.ios()) {
    return iosLink
  } else if (typeof device.android === 'function' && device.android()) {
    return androidLink
  }
  return desktopLink
}

export const getIframeYoutubeSrc = (videoId: string) => {
  return `https://www.youtube.com/embed/${videoId}?rel=0&amp;controls=0&amp;showinfo=0&amp;autoplay=0&amp;disablekb=1&amp;modestbranding=1`
}
