import React from 'react'

const PhoneIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.14744 4.68608C7.19996 3.63961 8.93312 3.82556 9.8144 5.00348L10.9058 6.45989C11.6237 7.41814 11.5597 8.75693 10.7078 9.60361L10.502 9.80945C10.4787 9.89587 10.4763 9.98657 10.495 10.0741C10.5495 10.4269 10.8444 11.1742 12.0794 12.4023C13.3144 13.6303 14.0669 13.9244 14.4249 13.9797C14.515 13.9989 14.6084 13.9962 14.6973 13.972L15.0502 13.6208C15.8078 12.8684 16.9702 12.7274 17.9077 13.2368L19.5595 14.1363C20.9753 14.9043 21.3325 16.8277 20.1736 17.9805L18.9446 19.2017C18.5572 19.5866 18.0365 19.9074 17.4017 19.9671C15.8363 20.1133 12.1893 19.9264 8.3554 16.1151C4.77752 12.5571 4.09083 9.45399 4.00348 7.92494C3.96024 7.15177 4.32521 6.49794 4.7905 6.03611L6.14744 4.68608ZM8.77658 5.78098C8.33811 5.19548 7.52169 5.14878 7.06159 5.60628L5.70378 6.95544C5.41838 7.23911 5.28173 7.55219 5.29903 7.85143C5.36821 9.06654 5.92172 11.8661 9.27041 15.1957C12.7834 18.688 16.0275 18.7926 17.2815 18.675C17.5375 18.6517 17.7918 18.5185 18.0296 18.2824L19.2577 17.0603C19.7576 16.5639 19.6477 15.6593 18.9403 15.2753L17.2884 14.3767C16.8318 14.1294 16.2973 14.2107 15.9652 14.541L15.5717 14.9328L15.1133 14.4727C15.5717 14.9328 15.5708 14.9337 15.57 14.9337L15.5691 14.9354L15.5665 14.938L15.5605 14.9432L15.5475 14.9553C15.5107 14.9889 15.4714 15.0195 15.4299 15.047C15.3607 15.0928 15.269 15.1438 15.154 15.1862C14.9205 15.2736 14.6109 15.3203 14.2286 15.2615C13.4788 15.1464 12.4851 14.6353 11.1644 13.3225C9.84467 12.0096 9.32922 11.022 9.21333 10.273C9.15366 9.89074 9.20122 9.58113 9.28944 9.34762C9.33836 9.21639 9.40784 9.09378 9.49527 8.98438L9.52295 8.95411L9.53506 8.94114L9.54025 8.93595L9.54284 8.93335L9.54457 8.93162L9.79365 8.68428C10.1638 8.31499 10.2157 7.70354 9.86716 7.23739L8.77658 5.78098Z"
        fill="white"
      />
    </svg>
  )
}

export default PhoneIcon
