import React from 'react'
import cn from 'classnames'
import * as s from './Container.module.scss'

interface ContainerProps {
  className?: string
  style?: React.CSSProperties
}

const Container: React.FC<ContainerProps> = ({
  className,
  style,
  children,
}) => {
  return (
    <div className={cn(s.container, className)} style={style}>
      {children}
    </div>
  )
}

export default Container
