import React from 'react'

const VkIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="54"
    height="54"
    viewBox="0 0 54 54"
    fill="none"
    color="#102538"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="54" height="54" rx="5" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.9682 14.9682C13 16.9365 13 20.1043 13 26.44V27.56C13 33.8957 13 37.0635 14.9682 39.0318C16.9365 41 20.1043 41 26.44 41H27.56C33.8957 41 37.0635 41 39.0318 39.0318C41 37.0635 41 33.8957 41 27.56V26.44C41 20.1043 41 16.9365 39.0318 14.9682C37.0635 13 33.8957 13 27.56 13H26.44C20.1043 13 16.9365 13 14.9682 14.9682ZM17.7251 21.5167C17.8767 28.7967 21.5167 33.1717 27.8984 33.1717H28.2601V29.0067C30.6051 29.2401 32.3783 30.9551 33.09 33.1717H36.4034C35.4934 29.8584 33.1016 28.0267 31.6083 27.3267C33.1016 26.4634 35.2016 24.3634 35.7033 21.5167H32.6932C32.0399 23.8267 30.1034 25.9267 28.2601 26.1251V21.5167H25.25V29.5901C23.3833 29.1234 21.0267 26.8601 20.9217 21.5167H17.7251Z"
      fill="currentColor"
    />
  </svg>
)

export default VkIcon
