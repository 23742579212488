import React, { RefObject } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import cn from 'classnames'

import { GlobalContext } from '../../context/GlobalContext'
import { useTranslations } from '../../hooks/use-translations'
import { AppRegionEnum } from '../../types/app-region.enum'
import LogoBase from '../../svg/logo-base'
import LogoEn from '../../svg/logo-en'
import ArrowDown from '../../svg/arrow-down'
import { IHeaderScreen } from '../../types/pages'

import * as s from './HeaderScreen.module.sass'

interface Props {
  data: IHeaderScreen
  logoRef: RefObject<SVGSVGElement>
  textRef: RefObject<HTMLHeadingElement>
}

const HeaderScreen: React.FC<Props> = ({ data, logoRef, textRef }) => {
  const { t } = useTranslations()
  const images = useStaticQuery(graphql`
    query {
      screen: file(relativePath: { eq: "header-prev.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      bim: file(relativePath: { eq: "bim-services/header.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      digital: file(relativePath: { eq: "digital-services/header.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      poster: file(relativePath: { eq: "header-poster.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      brand: file(relativePath: { eq: "header-brand.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
    }
  `)

  const { region } = React.useContext(GlobalContext)
  const ref = React.useRef<HTMLDivElement>(null)
  const handleScroll = () => {
    if (ref.current) {
      window.scrollTo({
        top: ref.current.offsetHeight,
        left: 0,
        behavior: 'smooth',
      })
    }
  }

  return (
    <div
      ref={ref}
      className={cn(s.wrapper, {
        [s.video_height]: data.videoData,
      })}
    >
      <div className={cn(s.wrapper_content, data.videoData && s.video_height)}>
        {data.text && (
          <div
            className={cn(s.wrapper_body, data.imageShadow && s.wrapper_shadow)}
          >
            {region === AppRegionEnum.Us ? (
              <LogoEn logoRef={logoRef} className={s.wrapper_logo} />
            ) : (
              <LogoBase logoRef={logoRef} className={s.wrapper_logo} />
            )}
            <span ref={textRef} className={s.wrapper_title}>
              {t(data.text)}
            </span>
            {data.videoData && (
              <ArrowDown className={s.arrow} onClick={handleScroll} />
            )}
          </div>
        )}
        {data.imageId ? (
          <GatsbyImage
            alt={t('_image_alt')}
            title={t('_image_title')}
            objectPosition={data.objectPosition}
            image={images[data.imageId].childImageSharp.gatsbyImageData}
            className={s.wrapper_img}
          />
        ) : (
          <>
            <video
              autoPlay
              loop
              muted
              playsInline
              className={s.wrapper_video}
              title={t('_image_title')}
            >
              <source src={data.videoData?.video} type="video/mp4" />
            </video>
            <GatsbyImage
              alt={t('_image_alt')}
              title={t('_image_title')}
              image={
                images[data.videoData?.posterId as string].childImageSharp
                  .gatsbyImageData
              }
              className={s.wrapper_img}
            />
          </>
        )}
      </div>
    </div>
  )
}

export default HeaderScreen
