import React from 'react'
import TelegramBotBtnIcon from '../../svg/telegram-bot-btn-icon'
import Link from './Link'

interface Props {
  href: string
  className?: string
}

const TelegramBotLink: React.FC<Props> = ({ href, className }) => {
  return (
    <div
      className={className}
      style={{
        display: 'flex',
        alignItems: 'center',
        fontSize: 18,
        fontWeight: 500,
      }}
    >
      <TelegramBotBtnIcon style={{ marginRight: 8 }} />
      <Link href={href} target="_blank" rel="nofollow noreferrer" color="white">
        @sgnl_support_bot
      </Link>
    </div>
  )
}

export default TelegramBotLink
