import React from 'react'
import cn from 'classnames'
import * as s from './Typography.module.scss'

const variants = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  body1: 'p',
  body2: 'span',
} as const

export type Size = 16 | 18 | 24 | 32 | 40 | 60 | 80
export type VariantType = keyof typeof variants

interface TypographyProps {
  variant: VariantType
  color?: 'dark' | 'white' | 'blue' | 'gray'
  size?: Size
  mb?: 16 | 24 | 32 | 48
  mt?: 16 | 24 | 32 | 48
  id?: string
  position?: 'start' | 'center' | 'end'
  className?: string
  style?: React.CSSProperties
  isUppercase?: boolean
  onClick?: () => void
}

const Typography: React.FC<TypographyProps> = ({
  variant,
  color = 'dark',
  size,
  position = 'start',
  className,
  isUppercase,
  mb,
  mt,
  id,
  style,
  onClick,
  children,
}) => {
  const Component = variants[variant]

  return (
    <Component
      id={id}
      onClick={onClick}
      style={{
        fontWeight:
          variant !== 'body1' && variant !== 'body2' ? 500 : undefined,
        ...style,
      }}
      className={cn(
        s.typography,
        color !== 'dark' && s[`text_${color}`],
        position !== 'start' && s[`text_${position}`],
        onClick && s[`hover_${color}`],
        size && s[`font_size_${size}`],
        mb && s[`margin_bottom_${mb}`],
        mt && s[`margin_top_${mt}`],
        isUppercase && 'is-uppercase',
        className
      )}
    >
      {children}
    </Component>
  )
}

export default Typography
