import React from 'react'
import cn from 'classnames'

import * as s from './BurgerButton.module.scss'

interface BurgerButtonProps {
  menuExpanded: boolean
}

const BurgerButton: React.FC<
  BurgerButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({ menuExpanded, ...props }) => {
  return (
    <button
      aria-label="menu"
      className={cn(s.burger, {
        [s.active]: menuExpanded,
      })}
      {...props}
    >
      <span aria-hidden="true" />
      <span aria-hidden="true" />
      <span aria-hidden="true" />
    </button>
  )
}

export default BurgerButton
