import React from 'react'
import cn from 'classnames'

const getLink = (href: string, type: string) => {
  if (type === 'email') return `mailto:${href}`
  if (type === 'tel') return `tel:${href.replace(/[^+\d]/g, '')}`
  return href
}

interface LinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  href: string
  typeLink?: 'link' | 'email' | 'tel'
  color?: 'dark' | 'blue' | 'white'
}

const Link: React.FC<LinkProps> = ({
  children,
  className,
  href,
  typeLink = 'link',
  color = 'blue',
  ...props
}) => {
  return (
    <a
      {...props}
      className={cn(className, {
        'has-text-primary': color === 'blue',
        'has-text-dark': color === 'dark',
        'has-text-white': color === 'white',
      })}
      href={getLink(href, typeLink)}
    >
      {children}
    </a>
  )
}

export default Link
