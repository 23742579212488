import React from 'react'

const PlayMarket: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      color="#0695D7"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.91246 7.20354C4.19819 6.93215 4.65606 6.93215 4.94179 7.20354L7.29452 9.54438C8.68352 8.85019 10.3214 8.52026 12 8.52026C13.6786 8.52026 15.3165 8.85019 16.7055 9.54438L19.0582 7.20354C19.3439 6.93215 19.8018 6.93215 20.0875 7.20354C20.3733 7.47493 20.3733 7.95627 20.0875 8.22766L17.8818 10.2759C20.3546 12.0049 21.9991 14.7434 21.9991 17.8836C21.9991 17.961 22.0011 18.0992 21.9991 18.1762H2.00086C1.99892 18.0992 2.00086 17.961 2.00086 17.8836C2.00086 14.7434 3.64542 12.0049 6.11815 10.2759L3.91246 8.22766C3.62674 7.95627 3.62674 7.47493 3.91246 7.20354ZM8.02975 14.9576C8.56852 14.9576 9.05908 14.5915 9.05908 14.0798C9.05908 13.568 8.56852 13.0556 8.02975 13.0556C7.49099 13.0556 7.14748 13.568 7.14748 14.0798C7.14748 14.5915 7.49099 14.9576 8.02975 14.9576ZM16.8525 14.0798C16.8525 14.5915 16.509 14.9576 15.9702 14.9576C15.4315 14.9576 14.9409 14.5915 14.9409 14.0798C14.9409 13.568 15.4315 13.0556 15.9702 13.0556C16.509 13.0556 16.8525 13.568 16.8525 14.0798Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default PlayMarket
