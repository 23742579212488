import React from 'react'

interface IProps extends React.SVGProps<SVGSVGElement> {
  logoRef?: React.RefObject<SVGSVGElement>
}

const LogoBase: React.FC<IProps> = ({ className, logoRef, ...props }) => (
  <svg
    width="106"
    height="134"
    viewBox="0 0 106 134"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    ref={logoRef}
    {...props}
  >
    <path
      d="M28.6781 3.99805C28.4914 5.39332 28.4114 6.84226 28.4114 8.31803C28.4114 23.666 38.8155 34.3184 59.4905 40.2751L65.2794 41.8314C67.8138 42.529 69.6012 42.9584 70.6416 43.3877C71.682 43.6828 73.0159 44.0853 74.3497 44.6756C77.1775 45.8026 77.9245 47.3857 77.9245 49.2102C77.9245 52.6179 74.5098 54.3352 67.8138 54.3352C58.7435 54.3352 52.501 50.0689 49.0863 41.6972L23.6628 55.7573C28.8649 71.1053 44.4711 80.7649 67.0668 80.7649C70.8817 80.7649 74.4564 80.4966 77.8178 79.9599L0 103.438L28.6781 3.99805Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M80.2984 13.7112C77.4706 6.7617 72.7221 3.19303 66.0261 3.19303C60.824 3.19303 58.1562 5.47376 58.1562 8.45213C58.1562 10.5719 59.3567 12.155 61.5709 13.2819C63.9452 14.4089 68.4003 15.831 75.0963 17.6824C79.2313 18.863 81.0987 19.346 84.0866 20.4998L105.028 0H104.868L80.2984 13.7112Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.88454 112.97C10.4585 112.645 11.7657 113.119 12.9128 113.769V108.825C11.2588 108.4 9.8716 108.375 7.95083 108.85C3.14891 110.073 0.587891 113.819 0.587891 117.364C0.587891 120.635 2.61536 122.009 5.78997 123.457C6.72367 123.906 8.29764 124.531 8.80451 125.405C9.01793 125.804 9.09796 126.179 9.09796 126.628C9.09796 128.326 7.71074 129.425 5.78997 129.5C4.216 129.575 2.90882 129.075 1.62831 128.451V132.746C3.06889 133.52 4.77623 133.919 6.51026 133.919C11.6323 133.919 14.5668 130.374 14.5668 125.904C14.5668 118.663 6.08342 119.961 6.08342 115.841C6.08342 114.493 7.23055 113.319 8.88454 112.97Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.2441 133.917H23.8463V104.052L18.2441 105.74V133.917Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M47.1114 113.704C47.1647 114.59 47.1647 115.502 47.1647 116.414C47.1647 118.915 47.0847 121.468 46.6579 123.943C45.7242 129.415 42.8964 134 38.0944 134C30.4647 134 28.0371 125.116 28.0371 118.342C28.0371 111.333 30.1446 101.954 38.0944 99.8437C42.4162 98.6973 45.2173 101.303 46.3911 105.992L40.9756 109.64C40.7088 107.712 40.2553 105.133 38.1478 105.602C34.333 106.435 34.0395 114.434 34.0395 117.456C34.0395 120.635 34.1729 128.216 38.2012 128.034C41.2424 127.904 41.5892 123.292 41.6425 120.53L38.0944 120.895V115.033L47.1114 113.704Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M63.5747 133.997L56.6115 110.422C56.825 111.849 56.905 113.356 56.905 114.836V134H51.3027V95.8603L56.5428 94.2823L63.5743 117.743C63.3076 115.94 63.1475 114.11 63.1475 112.279V92.291L68.8298 90.5796V133.997H63.5747Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M84.8846 133.993L83.8211 126.191L78.1922 126.459L77.1763 133.999H71.5762L78.3699 87.6846L83.5681 86.1382L90.8114 133.993H84.8846ZM82.9674 119.16C82.3805 114.814 81.7936 110.574 81.3668 106.201C81.2067 104.242 81.02 102.229 80.9132 100.297C80.6465 106.737 79.8462 113.15 79.0458 119.509L82.9674 119.16Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M93.5605 83.1236V133.999H105.005V124.259L99.1361 124.581V81.4526L93.5605 83.1236Z"
      fill="white"
    />
  </svg>
)

export default LogoBase
