import React from 'react'

const YoutubeBtnIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    color="white"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M23.4766 17.4945C23.1995 18.4762 22.3836 19.2527 21.3522 19.5165C19.4895 20 11.9927 20 11.9927 20C11.9927 20 4.49582 19.9853 2.63315 19.5018C1.60176 19.2381 0.785877 18.4615 0.508786 17.4799C-0.0607911 14.3297 -0.276307 9.52381 0.52418 6.50549C0.801271 5.52381 1.61715 4.74725 2.64855 4.48352C4.51122 4 12.0081 4 12.0081 4C12.0081 4 19.5049 4 21.3676 4.48352C22.399 4.74725 23.2149 5.52381 23.492 6.50549C24.0769 9.65568 24.2617 14.4615 23.4766 17.4945ZM9.60662 8.57143V15.4286L15.8258 12L9.60662 8.57143Z"
      fill="currentColor"
    />
  </svg>
)

export default YoutubeBtnIcon
