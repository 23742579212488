import React from 'react'
import cn from 'classnames'

import AppStore from '../../../svg/app-store'
import PlayMarket from '../../../svg/play-market'

import * as s from './button.module.scss'

export type Color = 'blue' | 'white' | 'transparent'
type Size = 'small' | 'normal' | 'medium' | 'large'

interface ButtonProps {
  color?: Color
  size?: Size
  hasIcon?: boolean
  isFullWidth?: boolean
}

export const Button: React.FC<
  ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({
  size = 'normal',
  color = 'blue',
  className,
  hasIcon,
  isFullWidth,
  children,
  ...props
}) => {
  return (
    <button
      className={cn(
        className,
        s.button,
        s[`button_${color}`],
        s[`button_${size}`],
        isFullWidth && s.isFullWidth
      )}
      {...props}
    >
      {hasIcon && (
        <>
          <AppStore
            width={24}
            height={24}
            style={{
              marginRight: 10,
              color: color === 'white' ? '#0695D7' : '#fff',
            }}
          />
          <PlayMarket
            style={{
              marginRight: 10,
              color: color === 'white' ? '#0695D7' : '#fff',
            }}
          />
        </>
      )}
      {children}
    </button>
  )
}
