import React, { useCallback, useRef } from 'react'
import { GlobalContext } from '../../context/GlobalContext'
import * as s from './languageSwitch.module.scss'
import cn from 'classnames'
import FlagEnIcon from '../../svg/flag-en-icon'
import FlagEsIcon from '../../svg/flag-es-icon'
import ArrowDownIcon from '../../svg/arrow-down-icon'
import ArrowUpIcon from '../../svg/arrow-up-icon'
import { useOutsideClick } from '../../hooks/useOutsideClick'
import { AppRegionEnum } from '../../types/app-region.enum'

interface ILanguagesItem {
  title: string
  locale: string
  hideOnRuReg?: boolean
  hideOnUsReg?: boolean
  Icon: React.FC<React.SVGProps<SVGSVGElement>>
}

const getLanguages = (): ILanguagesItem[] => [
  { title: 'EN', locale: 'us', Icon: FlagEnIcon, hideOnRuReg: true },
  { title: 'ES', locale: 'es', Icon: FlagEsIcon, hideOnRuReg: true },
]

interface IProps {
  type: string
  activeLang: string
  onLangClick: (lang: string) => void
}

type LanguageSwitchProps = Omit<IProps, 'type'>

const LanguageSwitchDesktop: React.FC<LanguageSwitchProps> = props => {
  const { isDesktop, region } = React.useContext(GlobalContext)
  const [visibleDropdown, setVisibleDropdown] = React.useState(false)
  const refDropdown = useRef<HTMLDivElement>(null)
  const handleHideDropdown = useCallback(() => {
    visibleDropdown && setVisibleDropdown(false)
  }, [visibleDropdown])
  useOutsideClick(refDropdown, handleHideDropdown)

  return (
    <>
      <div
        ref={refDropdown}
        onClick={() => isDesktop && setVisibleDropdown(!visibleDropdown)}
        className={cn(
          s.link,
          visibleDropdown && s.active_link,
          'flex items-center'
        )}
        style={{ cursor: 'pointer' }}
      >
        {getLanguages().find(language => language.locale === props.activeLang)
          ?.title || region}
        {visibleDropdown ? (
          <>
            <ArrowUpIcon />
            <div className={cn(s.dropdown)}>
              <ul className={s.dropdown_menu}>
                {(region === AppRegionEnum.Ru
                  ? getLanguages().filter(language => !language.hideOnRuReg)
                  : getLanguages().filter(language => !language.hideOnUsReg)
                ).map(({ title, locale, Icon }) => (
                  <li
                    key={title}
                    title={title}
                    className={s.dropdown_item}
                    onClick={() => {
                      props.onLangClick(locale)
                    }}
                  >
                    <Icon />
                    {title}
                  </li>
                ))}
              </ul>
            </div>
          </>
        ) : (
          <ArrowDownIcon />
        )}
      </div>
    </>
  )
}

const LanguageSwitchMobile: React.FC<LanguageSwitchProps> = props => {
  const { region } = React.useContext(GlobalContext)
  return (
    <>
      <div
        className="flex justify-center items-center"
        style={{ gap: '16px', fontSize: '18px', lineHeight: '24px' }}
      >
        {(region === AppRegionEnum.Ru
          ? getLanguages().filter(language => !language.hideOnRuReg)
          : getLanguages().filter(language => !language.hideOnUsReg)
        ).map(({ title, locale, Icon }) => (
          <button
            key={title}
            type="button"
            className={cn(
              s.btn,
              props.activeLang === locale ? s.btn_active : null
            )}
            onClick={() => {
              props.onLangClick(locale)
            }}
          >
            <Icon />
            {title}
          </button>
        ))}
      </div>
    </>
  )
}

export const LanguageSwitch: React.FC<IProps> = ({ type, ...props }) => {
  const Component =
    type === 'desktop' ? LanguageSwitchDesktop : LanguageSwitchMobile
  return <Component {...props} />
}
