import React from 'react'
import cn from 'classnames'

import * as s from './Checkbox.module.sass'

export interface CheckboxProps
  extends Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    'type' | 'onChange'
  > {
  onChange?: (checked: boolean) => void
}

const Checkbox: React.FC<CheckboxProps> = ({
  className,
  onChange,
  ...attrs
}) => {
  return (
    <label className={cn(s.wrap, className)}>
      <input
        type="checkbox"
        className={s.input}
        onChange={
          typeof onChange === 'function'
            ? () => onChange(!attrs.checked)
            : undefined
        }
        {...attrs}
      />
      <div className={s.control}>
        <svg
          width="8"
          height="8"
          viewBox="0 0 8 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={s.icn}
        >
          <path
            d="M1.14273 4.17136C1.01719 4.04582 0.846929 3.97529 0.669394 3.97529C0.49186 3.97529 0.321597 4.04582 0.196061 4.17136C0.0705253 4.29689 0 4.46715 0 4.64469C0 4.82222 0.0705253 4.99249 0.196061 5.11802L2.19606 7.11802C2.25835 7.17981 2.33223 7.22869 2.41346 7.26187C2.49468 7.29505 2.58166 7.31186 2.66939 7.31136C2.76066 7.30846 2.85036 7.28685 2.93293 7.24786C3.0155 7.20887 3.08917 7.15333 3.14939 7.08469L7.81606 1.75136C7.92381 1.61757 7.97577 1.44736 7.96109 1.27621C7.94642 1.10506 7.86624 0.946178 7.73728 0.832695C7.60832 0.719213 7.44054 0.659888 7.26891 0.667091C7.09728 0.674293 6.93506 0.747468 6.81606 0.871356L2.66939 5.67136L1.14273 4.17136Z"
            fill="#0695D7"
          />
        </svg>
      </div>
    </label>
  )
}

export default Checkbox
