import React from 'react'

const SignaxLogoBlueBack: React.FC<React.SVGProps<SVGSVGElement>> = ({
  style,
  ...props
}) => {
  return (
    <svg
      width="78"
      height="88"
      viewBox="0 0 78 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        fill: 'none',
        boxShadow:
          '4.048678398132324px 4.048678398132324px 4.048678398132324px 0px rgba(40, 187, 255, 1) inset, 1.012169599533081px 1.012169599533081px 20.243392944335938px 0px rgba(11, 152, 217, 0.3)',
        borderRadius: '0px 0px 8px 8px',
        ...style,
      }}
      {...props}
    >
      <g filter="url(#filter0_i_14718_56057)">
        <path
          d="M0 0H78V80C78 84.4183 74.4183 88 70 88H8C3.58172 88 0 84.4183 0 80V0Z"
          fill="url(#paint0_linear_14718_56057)"
        />
        <path
          d="M1 1H77V80C77 83.866 73.866 87 70 87H8C4.13401 87 1 83.866 1 80V1Z"
          stroke="url(#paint1_linear_14718_56057)"
          strokeWidth="2"
        />
        <path
          d="M25.3985 13.9094C25.3113 14.5758 25.2739 15.2678 25.2739 15.9727C25.2739 23.3031 30.1348 28.3908 39.7942 31.2358L42.4988 31.9791C43.6828 32.3123 44.5179 32.5174 45.004 32.7224C45.4901 32.8634 46.1133 33.0556 46.7365 33.3376C48.0576 33.8758 48.4066 34.6319 48.4066 35.5034C48.4066 37.1309 46.8113 37.9511 43.6828 37.9511C39.4452 37.9511 36.5287 35.9135 34.9333 31.915L23.0553 38.6303C25.4858 45.9607 32.7771 50.5743 43.3339 50.5743C45.1162 50.5743 46.7863 50.4461 48.3568 50.1898L12 61.4033L25.3985 13.9094Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M49.5156 18.5487C48.1945 15.2295 45.9759 13.525 42.8475 13.525C40.4171 13.525 39.1707 14.6143 39.1707 16.0369C39.1707 17.0493 39.7316 17.8054 40.7661 18.3436C41.8754 18.8819 43.9568 19.5611 47.0852 20.4454C49.0171 21.0092 49.8895 21.2399 51.2855 21.791L61.0695 12H60.9947L49.5156 18.5487Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.1507 65.9558C16.886 65.8007 17.4967 66.0273 18.0327 66.3374V63.9761C17.2599 63.7733 16.6118 63.7614 15.7144 63.988C13.471 64.5724 12.2744 66.3613 12.2744 68.0547C12.2744 69.617 13.2217 70.273 14.7049 70.9647C15.1411 71.1793 15.8765 71.4775 16.1133 71.8949C16.213 72.0857 16.2504 72.2646 16.2504 72.4793C16.2504 73.2902 15.6023 73.815 14.7049 73.8507C13.9695 73.8865 13.3588 73.648 12.7605 73.3499V75.4011C13.4336 75.7708 14.2312 75.9616 15.0414 75.9616C17.4344 75.9616 18.8054 74.2682 18.8054 72.1334C18.8054 68.6749 14.842 69.295 14.842 67.3273C14.842 66.6833 15.3779 66.1227 16.1507 65.9558Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.525 75.9604H23.1424V61.6966L20.525 62.5028V75.9604Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.0111 66.3063C34.0361 66.7293 34.0361 67.1649 34.0361 67.6004C34.0361 68.795 33.9987 70.0145 33.7993 71.1966C33.363 73.8098 32.0419 75.9999 29.7984 75.9999C26.2338 75.9999 25.0996 71.7566 25.0996 68.5212C25.0996 65.1739 26.0842 60.6942 29.7984 59.6862C31.8175 59.1387 33.1262 60.3831 33.6746 62.6229L31.1445 64.365C31.0198 63.4442 30.808 62.2123 29.8233 62.4363C28.041 62.8345 27.9039 66.6547 27.9039 68.0981C27.9039 69.6163 27.9662 73.2374 29.8483 73.1503C31.2691 73.088 31.4312 70.8855 31.4561 69.5665L29.7984 69.7407V66.9409L34.0111 66.3063Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M41.7015 75.9985L38.4483 64.7391C38.548 65.4204 38.5854 66.1403 38.5854 66.8473V76H35.968V57.7841L38.4162 57.0304L41.7013 68.2356C41.5767 67.3744 41.5019 66.5002 41.5019 65.6261V56.0794L44.1567 55.262V75.9985H41.7015Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M51.658 75.9971L51.1611 72.2705L48.5312 72.3986L48.0566 75.9997H45.4402L48.6143 53.8795L51.0429 53.1409L54.427 75.9971H51.658ZM50.7622 68.9128C50.488 66.8367 50.2138 64.8119 50.0144 62.723C49.9396 61.7875 49.8524 60.8263 49.8025 59.9036C49.6779 62.9793 49.304 66.0422 48.9301 69.0794L50.7622 68.9128Z"
          fill="white"
        />
        <path
          d="M65 75.9996L56.8449 51.3548L54.3406 52.1242L62.4687 75.9996"
          fill="white"
        />
        <path
          d="M58.1663 75.9996L64.5847 48.9716L61.5082 49.917L55.6636 75.9996"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_i_14718_56057"
          x="0"
          y="0"
          width="82.0487"
          height="92.0487"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="4.04868" dy="4.04868" />
          <feGaussianBlur stdDeviation="2.02434" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.158334 0 0 0 0 0.731643 0 0 0 0 1 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_14718_56057"
          />
        </filter>
        <linearGradient
          id="paint0_linear_14718_56057"
          x1="0"
          y1="0"
          x2="81.1819"
          y2="3.04039"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0695D7" />
          <stop offset="1" stopColor="#3AB7F0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_14718_56057"
          x1="0"
          y1="0"
          x2="81.1819"
          y2="3.04039"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#23A9E8" stopOpacity="0.99" />
          <stop offset="1" stopColor="#50B9EA" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default SignaxLogoBlueBack
