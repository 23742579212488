import React from 'react'

const FlagEnIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12.7334" r="11" fill="white" />
      <g clipPath="url(#clip0_24433_665)">
        <path
          d="M10 2.7334H0V3.7334H3.33333L10 8.7334V2.7334Z"
          fill="#3F51B5"
        />
        <path
          d="M12 10.2334L11 9.4834V4.7334H13V9.4834L12 10.2334Z"
          fill="#3F51B5"
        />
        <path d="M0 11.7334V13.7334H24V11.7334H0Z" fill="#3F51B5" />
        <path d="M7.33333 10.7334L0 5.2334V10.7334H7.33333Z" fill="#3F51B5" />
        <path d="M0 14.7334H5.33333L0 18.7334V14.7334Z" fill="#3F51B5" />
        <path
          d="M0 22.7334V21.7334H1.33333L10 15.2334V22.7334H0Z"
          fill="#3F51B5"
        />
        <path d="M11 20.7334V14.4834L13 15.9834V20.7334H11Z" fill="#3F51B5" />
        <path
          d="M14 16.7334L20.6667 21.7334H24V22.7334H14V16.7334Z"
          fill="#3F51B5"
        />
        <path d="M24 20.2334L16.6667 14.7334H24V20.2334Z" fill="#3F51B5" />
        <path d="M24 10.7334H16.6667L24 5.2334V10.7334Z" fill="#3F51B5" />
        <path
          d="M24 2.7334V3.7334H20.6667L14 8.7334V2.7334H24Z"
          fill="#3F51B5"
        />
        <path
          d="M11 1.7334H13V11.7334H24V13.7334H13V23.7334H11V13.7334H0V11.7334H11V1.7334Z"
          fill="#E53935"
        />
        <path d="M24 4.7334H22L14 10.7334H16L24 4.7334Z" fill="#E53935" />
        <path d="M24 20.7334H22L14 14.7334H16L24 20.7334Z" fill="#E53935" />
        <path d="M2 20.7334H0L8 14.7334H10L2 20.7334Z" fill="#E53935" />
        <path d="M10 10.7334H8L0 4.7334H2L10 10.7334Z" fill="#E53935" />
      </g>
      <defs>
        <clipPath id="clip0_24433_665">
          <rect x="2" y="2.7334" width="20" height="20" rx="10" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default FlagEnIcon
