import React from 'react'
import Modal from '../modal'
import Button from '../button/button'
import { useTranslations } from '../../hooks/use-translations'

interface FormDoneProps {
  onClose: () => void
}

const FormDone: React.FC<FormDoneProps> = ({ onClose }) => {
  const { t } = useTranslations()

  return (
    <Modal onClose={onClose}>
      <div className="content is-medium">
        <h6 className="title is-2 has-text-primary">
          {t('Your request has been sent')}.
        </h6>
        <p>{t('We will contact you')}.</p>
        <Button
          role="button"
          size="medium"
          color="dark-blue"
          className="py-3 px-6"
          onClick={onClose}
        >
          {t('Close')}
        </Button>
      </div>
    </Modal>
  )
}

export default FormDone
