import React from 'react'

const SignalLogoBlueBack: React.FC<React.SVGProps<SVGSVGElement>> = ({
  style,
  ...props
}) => {
  return (
    <svg
      width="78"
      height="88"
      viewBox="0 0 78 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        fill: 'none',
        boxShadow:
          '4.048678398132324px 4.048678398132324px 4.048678398132324px 0px rgba(40, 187, 255, 1) inset, 1.012169599533081px 1.012169599533081px 20.243392944335938px 0px rgba(11, 152, 217, 0.3)',
        borderRadius: '0px 0px 8px 8px',
        ...style,
      }}
      {...props}
    >
      <g filter="url(#filter0_i_15273_48200)">
        <path
          d="M0 0H78V80C78 84.4183 74.4183 88 70 88H8C3.58172 88 0 84.4183 0 80V0Z"
          fill="url(#paint0_linear_15273_48200)"
        />
        <path
          d="M1 1H77V80C77 83.866 73.866 87 70 87H8C4.13401 87 1 83.866 1 80V1Z"
          stroke="url(#paint1_linear_15273_48200)"
          strokeWidth="2"
        />
        <path
          d="M27.6526 13.9093C27.5637 14.5757 27.5256 15.2677 27.5256 15.9726C27.5256 23.3029 32.4786 28.3906 42.3212 31.2356L45.0771 31.9789C46.2836 32.3121 47.1345 32.5172 47.6298 32.7222C48.1251 32.8632 48.7601 33.0554 49.3951 33.3373C50.7414 33.8756 51.097 34.6317 51.097 35.5031C51.097 37.1307 49.4713 37.9509 46.2836 37.9509C41.9656 37.9509 38.9938 35.9132 37.3682 31.9148L25.265 38.6301C27.7415 45.9605 35.171 50.574 45.928 50.574C47.7441 50.574 49.4459 50.4458 51.0462 50.1895L14 61.4029L27.6526 13.9093Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M52.227 18.5486C50.8808 15.2295 48.6202 13.525 45.4325 13.525C42.956 13.525 41.686 14.6143 41.686 16.0368C41.686 17.0492 42.2575 17.8054 43.3116 18.3436C44.4419 18.8818 46.5628 19.5611 49.7505 20.4453C51.719 21.0092 52.608 21.2399 54.0304 21.7909L64 12H63.9238L52.227 18.5486Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.2291 65.9558C18.9784 65.8008 19.6008 66.0274 20.1469 66.3375V63.9761C19.3594 63.7734 18.699 63.7615 17.7846 63.9881C15.4986 64.5724 14.2794 66.3613 14.2794 68.0548C14.2794 69.6171 15.2446 70.273 16.7559 70.9647C17.2004 71.1794 17.9497 71.4775 18.191 71.8949C18.2926 72.0857 18.3307 72.2646 18.3307 72.4793C18.3307 73.2903 17.6703 73.815 16.7559 73.8508C16.0066 73.8865 15.3843 73.648 14.7747 73.3499V75.4011C15.4605 75.7708 16.2733 75.9616 17.0988 75.9616C19.5373 75.9616 20.9343 74.2682 20.9343 72.1334C20.9343 68.6749 16.8956 69.2951 16.8956 67.3273C16.8956 66.6833 17.4417 66.1228 18.2291 65.9558Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.6867 75.9598H25.3537V61.6961L22.6867 62.5023V75.9598Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.4283 66.3061C36.4537 66.7292 36.4537 67.1647 36.4537 67.6003C36.4537 68.7948 36.4156 70.0143 36.2124 71.1965C35.7679 73.8096 34.4217 75.9997 32.1357 75.9997C28.5034 75.9997 27.3477 71.7564 27.3477 68.5211C27.3477 65.1738 28.351 60.6941 32.1357 59.6861C34.1931 59.1386 35.5266 60.383 36.0854 62.6228L33.5073 64.3649C33.3803 63.4441 33.1644 62.2122 32.1611 62.4362C30.345 62.8344 30.2053 66.6545 30.2053 68.098C30.2053 69.6161 30.2687 73.2372 32.1865 73.1501C33.6343 73.0879 33.7994 70.8854 33.8248 69.5664L32.1357 69.7406V66.9407L36.4283 66.3061Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M44.2645 75.9986L40.9496 64.7392C41.0512 65.4205 41.0893 66.1404 41.0893 66.8474V76H38.4223V57.7842L40.9169 57.0305L44.2644 68.2357C44.1374 67.3744 44.0612 66.5003 44.0612 65.6262V56.0795L46.7663 55.2621V75.9986H44.2645Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M54.4103 75.9966L53.904 72.27L51.2243 72.3982L50.7407 75.9993H48.0747L51.3089 53.8792L53.7836 53.1406L57.2319 75.9966H54.4103ZM53.4976 68.9124C53.2182 66.8363 52.9388 64.8115 52.7356 62.7226C52.6594 61.7871 52.5705 60.8259 52.5197 59.9032C52.3927 62.9789 52.0117 66.0418 51.6307 69.079L53.4976 68.9124Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M58.5398 51.7004V75.9992H63.9882V71.3472L61.1942 71.501V50.9023L58.5398 51.7004Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_i_15273_48200"
          x="0"
          y="0"
          width="82.0487"
          height="92.0487"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="4.04868" dy="4.04868" />
          <feGaussianBlur stdDeviation="2.02434" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.158334 0 0 0 0 0.731643 0 0 0 0 1 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_15273_48200"
          />
        </filter>
        <linearGradient
          id="paint0_linear_15273_48200"
          x1="0"
          y1="0"
          x2="81.1819"
          y2="3.04039"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0695D7" />
          <stop offset="1" stopColor="#3AB7F0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_15273_48200"
          x1="0"
          y1="0"
          x2="81.1819"
          y2="3.04039"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#23A9E8" stopOpacity="0.99" />
          <stop offset="1" stopColor="#50B9EA" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default SignalLogoBlueBack
