import { IHeaderScreen, PathEnum } from '../../types/pages'
import { AppRegionEnum } from '../../types/app-region.enum'

export const headerScreenData: Record<AppRegionEnum, IHeaderScreen[]> = {
  [AppRegionEnum.Ru]: [
    {
      path: PathEnum.Value,
      imageId: 'screen',
      text: 'Your Digital Construction',
    },
  ],
  [AppRegionEnum.Us]: [],
}
