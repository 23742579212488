import React from 'react'

const WhatsappIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="54"
    height="54"
    viewBox="0 0 54 54"
    fill="none"
    color="#102538"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="54" height="54" rx="5" fill="white" />
    <g clipPath="url(#clip0_14971_5002)">
      <path
        d="M27 13C19.2806 13 13.0001 19.2805 13.0001 27C13.0001 29.4104 13.6228 31.7806 14.8036 33.8721L13.0226 40.2269C12.9642 40.4357 13.0208 40.6597 13.1717 40.8149C13.288 40.9348 13.4463 40.9999 13.6088 40.9999C13.6575 40.9999 13.7068 40.9938 13.7549 40.9823L20.3872 39.3394C22.4123 40.4265 24.6931 40.9999 27 40.9999C34.7195 40.9999 41 34.7194 41 27C41 19.2805 34.7195 13 27 13ZM34.0426 31.9401C33.7432 32.7692 32.3066 33.5258 31.6164 33.6274C30.9967 33.7181 30.2127 33.7571 29.352 33.4868C28.8304 33.3225 28.1608 33.1045 27.3032 32.7387C23.6979 31.2012 21.3435 27.6166 21.1633 27.3798C20.9837 27.143 19.6957 25.4557 19.6957 23.7094C19.6957 21.963 20.624 21.1041 20.9539 20.7487C21.2838 20.3932 21.6728 20.3043 21.9126 20.3043C22.1524 20.3043 22.3916 20.3074 22.6016 20.3171C22.8226 20.3281 23.119 20.2337 23.4106 20.9264C23.7101 21.6374 24.4289 23.3837 24.5178 23.5621C24.6079 23.7398 24.6675 23.9474 24.5482 24.1841C24.4289 24.4209 24.3693 24.5688 24.1891 24.7764C24.0089 24.984 23.8117 25.239 23.6498 25.3985C23.4696 25.5756 23.2828 25.7674 23.4921 26.1228C23.7015 26.4783 24.4228 27.6403 25.4917 28.5813C26.8643 29.7902 28.0227 30.1652 28.3818 30.3429C28.7409 30.5206 28.9509 30.4908 29.1603 30.254C29.3697 30.0166 30.0587 29.2174 30.2979 28.8626C30.5372 28.5077 30.777 28.5661 31.1069 28.6848C31.4368 28.8029 33.2039 29.6612 33.563 29.8389C33.9221 30.0166 34.1619 30.1055 34.252 30.2534C34.3421 30.4007 34.3421 31.1117 34.0426 31.9401Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_14971_5002">
        <rect
          width="28"
          height="28"
          fill="white"
          transform="translate(13 13)"
        />
      </clipPath>
    </defs>
  </svg>
)

export default WhatsappIcon
