import React from 'react'

const TelegramIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      width="54"
      height="54"
      viewBox="0 0 54 54"
      fill="none"
      color="#102538"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="54" height="54" rx="5" fill="white" />
      <g clipPath="url(#clip0_2059_6136)">
        <path
          d="M27 41C34.732 41 41 34.732 41 27C41 19.268 34.732 13 27 13C19.268 13 13 19.268 13 27C13 34.732 19.268 41 27 41Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.3372 26.8524C23.4185 25.0743 26.14 23.902 27.5017 23.3356C31.3896 21.7185 32.1975 21.4376 32.7241 21.4283C32.8399 21.4263 33.0988 21.455 33.2666 21.5911C33.4082 21.706 33.4472 21.8613 33.4658 21.9702C33.4845 22.0792 33.5077 22.3274 33.4892 22.5214C33.2785 24.7351 32.3669 30.1072 31.9031 32.5867C31.7068 33.6358 31.3204 33.9876 30.9463 34.022C30.1333 34.0968 29.516 33.4847 28.7285 32.9685C27.4963 32.1608 26.8002 31.658 25.6042 30.8698C24.222 29.9589 25.118 29.4583 25.9057 28.6401C26.1119 28.426 29.694 25.1678 29.7633 24.8722C29.772 24.8353 29.7801 24.6975 29.6982 24.6247C29.6163 24.552 29.4955 24.5768 29.4083 24.5966C29.2848 24.6247 27.3163 25.9257 23.5031 28.4998C22.9443 28.8835 22.4382 29.0704 21.9848 29.0606C21.4849 29.0498 20.5234 28.778 19.8086 28.5456C18.9318 28.2606 18.235 28.1099 18.2957 27.6259C18.3273 27.3738 18.6745 27.116 19.3372 26.8524Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2059_6136">
          <rect
            width="28"
            height="28"
            fill="white"
            transform="translate(13 13)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default TelegramIcon
