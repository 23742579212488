import React from 'react'

const ArrowUpIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.79289 15.9405C5.40237 15.55 5.40237 14.9168 5.79289 14.5263L10.7929 9.52629C11.1834 9.13577 11.8166 9.13577 12.2071 9.52629L17.2071 14.5263C17.5976 14.9168 17.5976 15.55 17.2071 15.9405C16.8166 16.331 16.1834 16.331 15.7929 15.9405L11.5 11.6476L7.20711 15.9405C6.81658 16.331 6.18342 16.331 5.79289 15.9405Z"
        fill="#B6BEC7"
      />
    </svg>
  )
}

export default ArrowUpIcon
