import React from 'react'

const EmailIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 7.75C20 7.3125 19.5 6 18 6H6C4.5 6 4 7.3125 4 7.75V17.0625C4 18.025 5.0375 18.8125 6 18.8125H18C18.9625 18.8125 20 18.025 20 17.0625V7.75ZM18.5 7.3125L12 11.6875L5.5 7.3125H18.5ZM18.5 17.5H5.5V9.0625L12 13.4336L18.5 9.0625V17.5Z"
        fill="white"
      />
    </svg>
  )
}

export default EmailIcon
