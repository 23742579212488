import { ILocalesMap } from './types'

export default function getChangePath(
  path: string,
  locale: string | undefined,
  locales: ILocalesMap,
  selectedLocale: string
): string {
  if (!locales) return path
  if (locale === selectedLocale || !locales[selectedLocale]) return path
  if (path.includes(`/${locale}/`)) {
    path = path.replace(
      `/${locale}/`,
      locales[selectedLocale].default ? '/' : `/${selectedLocale}/`
    )
  }
  path = locales[selectedLocale].default ? path : `/${selectedLocale}${path}`
  return path
}
