// extracted by mini-css-extract-plugin
export var typography = "Typography-module--typography--1CUmj";
export var text_white = "Typography-module--text_white--s2QLJ";
export var text_gray = "Typography-module--text_gray--H0ZWg";
export var text_blue = "Typography-module--text_blue--Ed7Zp";
export var text_center = "Typography-module--text_center--SNxMp";
export var text_end = "Typography-module--text_end--rHWYP";
export var hover_white = "Typography-module--hover_white--0qFNg";
export var hover_gray = "Typography-module--hover_gray--EQGB3";
export var hover_blue = "Typography-module--hover_blue--UT-ze";
export var margin_bottom_16 = "Typography-module--margin_bottom_16--jK10+";
export var margin_bottom_24 = "Typography-module--margin_bottom_24--EPWGa";
export var margin_bottom_32 = "Typography-module--margin_bottom_32--dcXgv";
export var margin_bottom_48 = "Typography-module--margin_bottom_48--711E4";
export var margin_top_16 = "Typography-module--margin_top_16--mP8G6";
export var margin_top_24 = "Typography-module--margin_top_24--1Xo92";
export var margin_top_32 = "Typography-module--margin_top_32--MN3Xm";
export var margin_top_48 = "Typography-module--margin_top_48--dYfxQ";
export var font_size_16 = "Typography-module--font_size_16--ficEH";
export var font_size_18 = "Typography-module--font_size_18--wOaUN";
export var font_size_24 = "Typography-module--font_size_24--LHMEL";
export var font_size_32 = "Typography-module--font_size_32--ZIEzJ";
export var font_size_40 = "Typography-module--font_size_40--qUx03";
export var font_size_60 = "Typography-module--font_size_60--iQMnQ";
export var font_size_80 = "Typography-module--font_size_80--6Faqr";