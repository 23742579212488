// extracted by mini-css-extract-plugin
export var video_height = "HeaderScreen-module--video_height--sWMtl";
export var wrapper = "HeaderScreen-module--wrapper--8bPFA";
export var wrapper_content = "HeaderScreen-module--wrapper_content--++0bL";
export var wrapper_body = "HeaderScreen-module--wrapper_body--QaYkv";
export var wrapper_shadow = "HeaderScreen-module--wrapper_shadow--JjNpk";
export var wrapper_logo = "HeaderScreen-module--wrapper_logo--OAeao";
export var wrapper_title = "HeaderScreen-module--wrapper_title--39FbI";
export var wrapper_img = "HeaderScreen-module--wrapper_img--zHwTY";
export var wrapper_video = "HeaderScreen-module--wrapper_video--eRgGt";
export var arrow = "HeaderScreen-module--arrow--PNXwH";