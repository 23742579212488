import React from 'react'

const TelegramBtnIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    color="white"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.30615 10.8879C7.40641 8.62993 10.8074 7.14134 12.5091 6.42212C17.3677 4.36862 18.3773 4.0119 19.0354 4.00013C19.1801 3.99753 19.5037 4.03398 19.7133 4.20682C19.8903 4.35275 19.939 4.5499 19.9623 4.68826C19.9856 4.82663 20.0146 5.14183 19.9916 5.38811C19.7283 8.1992 18.589 15.021 18.0094 18.1694C17.7642 19.5017 17.2813 19.9484 16.8138 19.9921C15.7978 20.0871 15.0263 19.3098 14.0422 18.6543C12.5024 17.6287 11.6325 16.9902 10.1378 15.9893C8.4105 14.8327 9.53027 14.1969 10.5147 13.158C10.7723 12.8861 15.2488 8.74867 15.3354 8.37335C15.3463 8.32641 15.3563 8.15143 15.254 8.05904C15.1517 7.96664 15.0007 7.99824 14.8918 8.02337C14.7373 8.05898 12.2775 9.71112 7.51212 12.9798C6.81389 13.467 6.18145 13.7044 5.61481 13.6919C4.99014 13.6782 3.78851 13.333 2.89523 13.038C1.79958 12.6761 0.928776 12.4847 1.0046 11.8701C1.0441 11.55 1.47795 11.2226 2.30615 10.8879Z"
      fill="currentColor"
    />
  </svg>
)

export default TelegramBtnIcon
