import React from 'react'

const AppStore: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      width="48"
      height="49"
      viewBox="0 0 48 49"
      fill="none"
      color="#0695D7"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M36.7102 26.4971C36.7757 33.4394 42.9007 35.7496 42.9686 35.7791C42.9168 35.942 41.9899 39.0714 39.7416 42.3038C37.7981 45.0983 35.781 47.8826 32.6034 47.9403C29.4811 47.9969 28.4772 46.1188 24.9075 46.1188C21.3389 46.1188 20.2235 47.8826 17.2679 47.9969C14.2007 48.1111 11.8651 44.975 9.90542 42.1906C5.90106 36.4952 2.84089 26.0967 6.94992 19.0775C8.9912 15.5917 12.6391 13.3844 16.5986 13.3278C19.6105 13.2713 22.4533 15.3213 24.2945 15.3213C26.1346 15.3213 29.5892 12.856 33.221 13.2181C34.7414 13.2803 39.0091 13.8223 41.7495 17.7685C41.5287 17.9032 36.6572 20.6932 36.7102 26.4971ZM30.8422 9.45003C32.4706 7.51089 33.5666 4.81143 33.2676 2.12549C30.9204 2.2183 28.0822 3.66424 26.3986 5.60231C24.8898 7.31856 23.5684 10.0655 23.9249 12.6983C26.5411 12.8974 29.2138 11.3904 30.8422 9.45003Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default AppStore
