import React from 'react'

const PhoneFilledBtnIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    color="white"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.3889 4.57638C6.83574 3.87752 5.8058 3.82177 5.22537 4.36786L3.51244 5.97825C3.1524 6.31684 2.98002 6.69053 3.00184 7.04771C3.08912 8.49809 3.78739 11.8396 8.01188 15.814C12.4437 19.9824 16.5362 20.1073 18.1182 19.9669C18.4411 19.9391 18.7619 19.7801 19.0619 19.4983L20.6112 18.0396C21.2418 17.4471 21.1032 16.3673 20.2108 15.909L18.1269 14.8364C17.5508 14.5412 16.8766 14.6382 16.4576 15.0325L15.9612 15.5002L15.9546 15.5064C16.5014 14.9836 15.959 15.5012 15.959 15.5012L15.9579 15.5033L15.9546 15.5064L15.947 15.5126L15.9306 15.527C15.8842 15.5671 15.8346 15.6037 15.7822 15.6364C15.695 15.6912 15.5793 15.7521 15.4342 15.8026C15.1396 15.9069 14.749 15.9627 14.2668 15.8925C13.3209 15.7552 12.0673 15.1451 10.4013 13.578C8.73633 12.011 8.08607 10.8321 7.93987 9.93815C7.86459 9.48187 7.9246 9.11231 8.03589 8.83359C8.0976 8.67695 8.18525 8.5306 8.29555 8.40002L8.33046 8.36389L8.34574 8.34841L8.35774 8.33705L8.67196 8.04182C9.13892 7.60102 9.20439 6.87119 8.7647 6.31478L7.3889 4.57638Z"
      fill="currentColor"
    />
  </svg>
)

export default PhoneFilledBtnIcon
