import React from 'react'

const SignaxLogoMobileBlueBack: React.FC<React.SVGProps<SVGSVGElement>> = ({
  style,
  ...props
}) => {
  return (
    <svg
      width="56"
      height="71"
      viewBox="0 0 56 71"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        fill: 'none',
        boxShadow:
          '4.048678398132324px 4.048678398132324px 4.048678398132324px 0px rgba(40, 187, 255, 1) inset, 1.012169599533081px 1.012169599533081px 20.243392944335938px 0px rgba(11, 152, 217, 0.3)',
        borderRadius: '0px 0px 8px 8px',
        ...style,
      }}
      {...props}
    >
      <g filter="url(#filter0_i_14699_94016)">
        <path
          d="M0 0.733643H56V62.7336C56 67.1519 52.4183 70.7336 48 70.7336H8C3.58172 70.7336 0 67.1519 0 62.7336V0.733643Z"
          fill="url(#paint0_linear_14699_94016)"
        />
        <path
          d="M1 1.73364H55V62.7336C55 66.5996 51.866 69.7336 48 69.7336H8C4.13401 69.7336 1 66.5996 1 62.7336V1.73364Z"
          stroke="url(#paint1_linear_14699_94016)"
          strokeWidth="2"
        />
        <path
          d="M18.6066 14.1063C18.544 14.5852 18.5172 15.0826 18.5172 15.5893C18.5172 20.858 22.0024 24.5148 28.928 26.5596L30.8672 27.0938C31.7161 27.3333 32.3149 27.4807 32.6634 27.6281C33.0119 27.7294 33.4587 27.8676 33.9055 28.0702C34.8528 28.4571 35.103 29.0005 35.103 29.6269C35.103 30.7967 33.9592 31.3862 31.7161 31.3862C28.6778 31.3862 26.5867 29.9216 25.4428 27.0478L16.9265 31.8744C18.6691 37.1431 23.8969 40.4591 31.4659 40.4591C32.7438 40.4591 33.9413 40.367 35.0673 40.1827L9 48.2424L18.6066 14.1063Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.8981 17.4405C34.9508 15.0548 33.3602 13.8298 31.1171 13.8298C29.3746 13.8298 28.4809 14.6127 28.4809 15.6351C28.4809 16.3628 28.8831 16.9062 29.6248 17.2931C30.4201 17.68 31.9125 18.1682 34.1555 18.8037C35.5406 19.209 36.1662 19.3748 37.167 19.7709L44.182 12.7336H44.1284L35.8981 17.4405Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.9759 51.5144C12.5031 51.403 12.941 51.5659 13.3253 51.7887V50.0915C12.7712 49.9458 12.3065 49.9372 11.6631 50.1001C10.0546 50.5201 9.19668 51.8059 9.19668 53.0231C9.19668 54.146 9.87584 54.6174 10.9393 55.1146C11.252 55.2689 11.7793 55.4832 11.9491 55.7832C12.0206 55.9203 12.0474 56.0489 12.0474 56.2032C12.0474 56.7861 11.5827 57.1632 10.9393 57.1889C10.412 57.2146 9.97414 57.0432 9.5452 56.8289V58.3033C10.0278 58.569 10.5997 58.7061 11.1805 58.7061C12.8963 58.7061 13.8793 57.4889 13.8793 55.9546C13.8793 53.4688 11.0376 53.9145 11.0376 52.5002C11.0376 52.0373 11.4218 51.6344 11.9759 51.5144Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.1124 58.7053H16.989V48.4532L15.1124 49.0326V58.7053Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.7814 51.7663C24.7993 52.0704 24.7993 52.3834 24.7993 52.6964C24.7993 53.555 24.7725 54.4315 24.6295 55.2812C24.3167 57.1594 23.3695 58.7335 21.7609 58.7335C19.2051 58.7335 18.3919 55.6837 18.3919 53.3583C18.3919 50.9524 19.0979 47.7326 21.7609 47.0081C23.2086 46.6146 24.1469 47.509 24.5401 49.1189L22.7261 50.371C22.6367 49.7092 22.4848 48.8237 21.7788 48.9847C20.5009 49.2709 20.4026 52.0167 20.4026 53.0542C20.4026 54.1453 20.4473 56.748 21.7967 56.6854C22.8154 56.6407 22.9316 55.0576 22.9495 54.1096L21.7609 54.2348V52.2224L24.7814 51.7663Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.2953 58.7325L27.9628 50.6398C28.0343 51.1295 28.0611 51.6469 28.0611 52.1551V58.7336H26.1845V45.6409L27.9398 45.0992L30.2952 53.1529C30.2058 52.5339 30.1522 51.9056 30.1522 51.2773V44.4157L32.0557 43.8282V58.7325H30.2953Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.4342 58.7318L37.078 56.0532L35.1924 56.1453L34.8521 58.7336H32.9762L35.2519 42.8348L36.9932 42.3039L39.4196 58.7318H37.4342ZM36.792 53.6399C36.5954 52.1478 36.3988 50.6924 36.2558 49.191C36.2022 48.5186 36.1396 47.8278 36.1039 47.1646C36.0145 49.3752 35.7464 51.5767 35.4784 53.7597L36.792 53.6399Z"
          fill="white"
        />
        <path
          d="M47 58.7332L41.1529 41.0198L39.3574 41.5728L45.1851 58.7332"
          fill="white"
        />
        <path
          d="M42.1009 58.7335L46.7027 39.3072L44.497 39.9867L40.3064 58.7335"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_i_14699_94016"
          x="0"
          y="0.733643"
          width="60.0487"
          height="74.0487"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="4.04868" dy="4.04868" />
          <feGaussianBlur stdDeviation="2.02434" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.158334 0 0 0 0 0.731643 0 0 0 0 1 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_14699_94016"
          />
        </filter>
        <linearGradient
          id="paint0_linear_14699_94016"
          x1="0"
          y1="0.733643"
          x2="58.2996"
          y2="2.70431"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0695D7" />
          <stop offset="1" stopColor="#3AB7F0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_14699_94016"
          x1="0"
          y1="0.733643"
          x2="58.2996"
          y2="2.70431"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#23A9E8" stopOpacity="0.99" />
          <stop offset="1" stopColor="#50B9EA" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default SignaxLogoMobileBlueBack
