import React from 'react'
import cn from 'classnames'
import * as s from './button.module.sass'

interface IProps {
  color?: 'light-blue' | 'dark-blue' | 'gray' | 'white'
  text?: string
  size?: 'normal' | 'medium'
  isFullWidth?: boolean
  isBoxShadow?: boolean
}

const Button: React.FC<
  IProps & React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({
  color = 'light-blue',
  text = '',
  size = 'normal',
  className = '',
  isFullWidth,
  isBoxShadow = true,
  children,
  ...props
}) => (
  <button
    className={cn(
      'button is-uppercase',
      getClass(color),
      s.el,
      s[`el_${size}`],
      isFullWidth && s.isFullWidth,
      isBoxShadow && s.box_shadow,
      className
    )}
    {...props}
  >
    {children || text}
  </button>
)

export default Button

function getClass(c: IProps['color']) {
  switch (c) {
    case 'dark-blue':
      return 'is-dark has-text-white'
    case 'white':
      return 'is-white has-text-primary'
    case 'gray':
      return s.el_gray
    default:
      return 'is-primary has-text-white'
  }
}
