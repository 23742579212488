import React from 'react'

const ArrowDownIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.2071 9.52629C17.5976 9.91682 17.5976 10.55 17.2071 10.9405L12.2071 15.9405C11.8166 16.331 11.1834 16.331 10.7929 15.9405L5.79289 10.9405C5.40237 10.55 5.40237 9.91682 5.79289 9.52629C6.18342 9.13577 6.81658 9.13577 7.20711 9.52629L11.5 13.8192L15.7929 9.52629C16.1834 9.13577 16.8166 9.13577 17.2071 9.52629Z"
        fill="#B6BEC7"
      />
    </svg>
  )
}

export default ArrowDownIcon
