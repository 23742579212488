import React from 'react'

const VkBtnIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    color="white"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.9824 19C5.46589 19 1.17864 13.7447 1 5H4.76515C4.88882 11.4184 7.66452 14.1371 9.86314 14.6977V5H13.4086V10.5355C15.5797 10.2973 17.8605 7.77477 18.63 5H22.1754C21.5845 8.41942 19.111 10.9419 17.3521 11.979C19.111 12.8198 21.9282 15.02 23 19H19.0973C18.2591 16.3373 16.1706 14.2773 13.4086 13.997V19H12.9824Z"
      fill="currentColor"
    />
  </svg>
)

export default VkBtnIcon
