import React from 'react'
import { Button, Color } from './Button'
import Link from './Link'

type LinkType = 'link' | 'email' | 'tel'

interface ContactButtonProps {
  Icon: JSX.Element
  href: string
  type?: LinkType
  className?: string
  style?: React.CSSProperties
  color?: Color
}

const ContactButton = ({
  type = 'link',
  href,
  className,
  style,
  Icon,
  color,
}: ContactButtonProps) => {
  return (
    <Link
      href={href}
      rel="nofollow noreferrer"
      target="_blank"
      typeLink={type}
      className={className}
      style={{ width: 40, height: 40, borderRadius: '50%', ...style }}
    >
      <Button
        color={color}
        size="small"
        className="w-100 h-100"
        style={{ borderRadius: '50%', padding: 0 }}
      >
        {Icon}
      </Button>
    </Link>
  )
}

export default ContactButton
