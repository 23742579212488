import React from 'react'

const YoutubeIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="54"
    height="54"
    viewBox="0 0 54 54"
    fill="none"
    color="#102538"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="54" height="54" rx="5" fill="white" />
    <path
      d="M39.4338 33.1813C39.1336 34.2857 38.2497 35.1593 37.1323 35.456C35.1144 36 26.9925 36 26.9925 36C26.9925 36 18.8706 35.9835 16.8527 35.4396C15.7353 35.1429 14.8514 34.2692 14.5512 33.1648C13.9341 29.6209 13.7007 24.2143 14.5679 20.8187C14.8681 19.7143 15.752 18.8407 16.8694 18.544C18.8873 18 27.0092 18 27.0092 18C27.0092 18 35.131 18 37.149 18.544C38.2664 18.8407 39.1503 19.7143 39.4505 20.8187C40.0842 24.3626 40.2843 29.7692 39.4338 33.1813ZM24.4075 23.1429V30.8571L31.1452 27L24.4075 23.1429Z"
      fill="currentColor"
    />
  </svg>
)

export default YoutubeIcon
