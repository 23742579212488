import { createContext } from 'react'
import { PageProps } from 'gatsby'
import { DEFAULT_REGION, DEFAULT_SITE_TITLE } from '../data'

export interface ILocationState {
  tryModalOpened?: boolean
  presentationModalOpened?: boolean
}

interface IGlobalContext {
  location?: PageProps<unknown, unknown, ILocationState>['location']
  siteTitle: string
  isDesktop?: boolean
  region: string
}

export const GlobalContext = createContext<IGlobalContext>({
  siteTitle: DEFAULT_SITE_TITLE,
  isDesktop: false,
  region: DEFAULT_REGION,
})
