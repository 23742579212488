import React from 'react'
import ReactPhoneInput, { PhoneInputProps } from 'react-phone-input-2'
import cn from 'classnames'

import './PhoneInput.styles.sass'

interface IPhoneInputProps extends PhoneInputProps {
  hasError?: boolean
}

const PhoneInput: React.FC<IPhoneInputProps> = ({ hasError, ...props }) => {
  return (
    <ReactPhoneInput
      inputClass={cn(
        'input-phone',
        hasError && 'input-phone-error',
        props.inputClass
      )}
      containerClass={cn('input-phone-container', props.containerClass)}
      dropdownClass={cn('country-code-dropdown', props.dropdownClass)}
      buttonClass={cn('input-flag-dropdown', props.buttonClass)}
      {...props}
    />
  )
}

export default PhoneInput
