import React from 'react'

const EmailFilledBtnIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    color="white"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.84902 5.36418L12 10.7982L20.151 5.36418C19.8255 5.13475 19.4285 5 19 5H5C4.57152 5 4.1745 5.13475 3.84902 5.36418ZM21 7.20188L12 13.2019L3 7.20186V17C3 18.1046 3.89543 19 5 19H19C20.1046 19 21 18.1046 21 17V7.20188Z"
      fill="currentColor"
    />
  </svg>
)

export default EmailFilledBtnIcon
