import React from 'react'

const LinkedinIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="54"
    height="54"
    viewBox="0 0 54 54"
    fill="none"
    color="#102538"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="54" height="54" rx="5" fill="white" />
    <g clipPath="url(#clip0_2059_6131)">
      <path
        d="M15 18.6641C15 17.8919 15.2703 17.2548 15.8108 16.7529C16.3513 16.251 17.0541 16 17.9189 16C18.7683 16 19.4556 16.2471 19.9807 16.7413C20.5212 17.251 20.7915 17.915 20.7915 18.7336C20.7915 19.4749 20.529 20.0926 20.0039 20.5869C19.4633 21.0965 18.7529 21.3514 17.8726 21.3514H17.8494C17 21.3514 16.3127 21.0965 15.7876 20.5869C15.2625 20.0772 15 19.4363 15 18.6641ZM15.3012 38.9344V23.4595H20.444V38.9344H15.3012ZM23.2934 38.9344H28.4363V30.2934C28.4363 29.7529 28.4981 29.3359 28.6216 29.0425C28.8378 28.5174 29.166 28.0733 29.6062 27.7104C30.0463 27.3475 30.5984 27.166 31.2625 27.166C32.9923 27.166 33.8571 28.332 33.8571 30.6641V38.9344H39V30.0618C39 27.7761 38.4595 26.0425 37.3784 24.861C36.2973 23.6795 34.8687 23.0888 33.0927 23.0888C31.1004 23.0888 29.5483 23.9459 28.4363 25.6602V25.7066H28.4131L28.4363 25.6602V23.4595H23.2934C23.3243 23.9537 23.3398 25.4903 23.3398 28.0695C23.3398 30.6486 23.3243 34.2703 23.2934 38.9344Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_2059_6131">
        <rect
          width="24"
          height="22.9344"
          fill="white"
          transform="translate(15 16)"
        />
      </clipPath>
    </defs>
  </svg>
)

export default LinkedinIcon
