import React from 'react'
import cn from 'classnames'

import * as s from './Input.module.sass'

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string
  hasError?: boolean
  fullWidth?: boolean
}

const Input: React.FC<InputProps> = ({
  label,
  className,
  hasError,
  fullWidth,
  value,
  type = 'text',
  ...attr
}) => {
  return (
    <div className={cn(s.box, fullWidth && s.full_width, className)}>
      <input
        className={cn(s.input, hasError && s.error)}
        value={value}
        style={value ? { padding: '12px 12px 0px 12px' } : undefined}
        {...attr}
      />
      <label
        className={s.label}
        style={value ? { top: '25%', fontSize: 12 } : undefined}
      >
        {label}
      </label>
    </div>
  )
}

export default Input
